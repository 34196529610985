import React from 'react'
import { Link } from 'react-router-dom';

function PaymentsBreadCrumb() {
  return (
    <section className="breadcrumb-area breadcrumb-bg">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="breadcrumb-content text-center">
            <h2><span>Payment Method</span></h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link style={{textDecoration:'none'}} to="/">Top Up</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Payment Method</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default PaymentsBreadCrumb