import React from 'react'
import HeaderTwo from '../../components/header/HeaderTwo'
import Profile from './Profile'
import Footer from '../../components/footer/Footer'

function ProfilesDetail() {
  return (
    <>
    <HeaderTwo/>
    <main>
        <Profile/>
    </main>
    <Footer/>
    </>
  )
}

export default ProfilesDetail