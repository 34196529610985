import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import CIcon from "@coreui/icons-react";
import { cilAvTimer, cilCopy } from "@coreui/icons";
import dayjs from "dayjs";
import API from "../../api"; // Ensure this path is correct
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const bankImages = {
  "014":
    "https://storage.googleapis.com/nero-assets/payment-method/bca-logo.svg",
  "008":
    "https://storage.googleapis.com/nero-assets/payment-method/mandiri-logo.svg",
  "002":
    "https://storage.googleapis.com/nero-assets/payment-method/bri-logo.svg",
  "009":
    "https://storage.googleapis.com/nero-assets/payment-method/bni-logo.svg",
  "013":
    "https://storage.googleapis.com/nero-assets/payment-method/permata-logo.svg",
  "011":
    "https://storage.googleapis.com/nero-assets/payment-method/danamon-logo.svg",
  "022":
    "https://storage.googleapis.com/nero-assets/payment-method/cimb-logo.svg",
  "153": "https://storage.googleapis.com/nero-assets/payment-method/bss-logo.svg",
};

const addMargin = (amount) => Math.round(amount * 1.05);

function ConfirmationTopUp() {
  const location = useLocation();
  const history = useHistory();
  const { userData, paymentDetails } = location.state || {
    userData: null,
    paymentDetails: null,
  };
  const [expiryTime, setExpiryTime] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("");
  console.log('status', paymentStatus)
  useEffect(() => {
    if (userData) {
      const submissionDate = dayjs(userData.requested_date);
      const expiryDate = submissionDate.add(30, "minute");
      setExpiryTime(expiryDate.format("YYYY-MM-DD HH:mm:ss"));
    }
  }, [userData]);

  useEffect(() => {
    if (userData && userData.transaction_id) {
      handleCheckStatus();
    }

    // Redirect for E-Wallet and Credit Card if URL is provided
    if (
      (userData.payment_method === "E-Wallet" ||
        userData.payment_method === "Credit Card") &&
      paymentDetails.url
    ) {
      const popupWidth = 600;
      const popupHeight = 600;
      const left = window.innerWidth / 2 - popupWidth / 2;
      const top = window.innerHeight / 2 - popupHeight / 2;

      window.open(
        paymentDetails.url,
        "paymentPopup",
        `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`
      );
    }
  }, [userData, paymentDetails.url]);

  if (!userData || !paymentDetails) {
    return <p>Invalid access</p>;
  }

  const handleBackToHome = () => {
    history.push("/top-up");
  };

  const updatePaymentStatusInDatabase = async () => {
    try {
      await API.put("update-payments-by-user", {
        payment_id: userData.transaction_id,
      });
    } catch (error) {
      toast.error("An error occurred while updating payment status", {
        position: "top-right",
      });
    }
  };

  const handleCheckStatus = async () => {
    try {
      const response = await API.post('/check-trx', { id: userData.transaction_id });
  
      if (response.data?.status) {
        const updatedStatus = response.data.status; // Get the status from response
  
        // Update the payment status
        setPaymentStatus(updatedStatus);
  
        // Update payment status in the database
        await updatePaymentStatusInDatabase();
  
        if (updatedStatus === 'pending') {
          toast.info("Payment is still pending", {
            position: 'top-right',
          });
        } else if (updatedStatus === 'success') {
          toast.success("Payment successful", {
            position: 'top-right',
          });
          setTimeout(() => {
            history.push({
              pathname: `/success-payment/${userData.transaction_id}`,
              state: { userData, paymentDetails }
            });
          }, 4000); // Redirect to /success-payment/:transaction_id after successful payment
        } else {
          toast.error("Payment failed or unknown status", {
            position: 'top-right',
          });
        }
      } else {
        toast.error("Failed to check payment status", {
          position: 'top-right',
        });
      }
    } catch (error) {
      console.error("Error during transaction status check:", error);
      toast.error("An error occurred while checking payment status", {
        position: 'top-right',
      });
    }
  };
  
  
  
  
  
  
  

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("VA Number copied to clipboard!", {
          position: "top-right",
        });
      })
      .catch((err) => {
        toast.error("Failed to copy VA Number", {
          position: "top-right",
        });
      });
  };

  const renderPaymentDetails = () => {
    const amountWithMargin = addMargin(userData.amount);
  
    if (userData.payment_method.toLowerCase() === "virtual account") {
      const bankImage = bankImages[userData.code];
      return (
        <div className="method-wrapper">
          <div>
            <h5 className="text-black text-xl md:text-2xl font-semibold">Virtual Account</h5>
            <p>
              <strong>Bank Code:</strong> {userData.code}
            </p>
            <p>
              <strong>VA Number:</strong> {paymentDetails.vaNumber}
              <Button style={{ backgroundColor: 'transparent', border: 'none', marginLeft: "2px" }} onClick={() => copyToClipboard(paymentDetails.vaNumber)}>
                <CIcon style={{ color: "black" }} icon={cilCopy} />
              </Button>
            </p>
            <p>
              <strong>Jumlah yang Harus Dibayar:</strong> {formatCurrency(amountWithMargin)}
            </p>
            <p>
              <strong>Status Pembayaran:</strong> {paymentStatus} {/* Updated field */}
            </p>
            <p>
              <strong>Tanggal Pembayaran:</strong> {userData.paidDate ? dayjs(userData.paidDate).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
            </p>
            <p>
              <strong>Settlement Date:</strong> {userData.settleDate ? dayjs(userData.settleDate).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
            </p>
          </div>
          {bankImage && <img style={{ width: '40%' }} src={bankImage} alt={`Bank ${userData.code}`} />}
        </div>
      );
    } else if (userData.payment_method.toLowerCase() === "qris") {
      return (
        <div className="method-wrapper">
          <div>
            <h5 className="text-black text-xl md:text-2xl font-semibold">QRIS</h5>
            <p>
              <strong>Jumlah yang Harus Dibayar:</strong> {formatCurrency(amountWithMargin)}
            </p>
            <p>
              <strong>Status Pembayaran:</strong> {paymentStatus} {/* Updated field */}
            </p>
            <p>
              <strong>Tanggal Pembayaran:</strong> {userData.paidDate ? dayjs(userData.paidDate).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
            </p>
            <p>
              <strong>Settlement Date:</strong> {userData.settleDate ? dayjs(userData.settleDate).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
            </p>
          </div>
          <img style={{ width: '40%' }} src={paymentDetails.image} alt="QR Code" />
        </div>
      );
    } else if (userData.payment_method.toLowerCase() === "e-wallet" || userData.payment_method.toLowerCase() === "credit card") {
      return (
        <>
          <h5 className="text-black text-xl md:text-2xl font-semibold">{userData.payment_method}</h5>
          {paymentDetails.eWallet && (
            <>
              <p>
                <strong>View Name:</strong> {paymentDetails.eWallet.viewName}
              </p>
              <p>
                <strong>Channel:</strong> {paymentDetails.eWallet.channel}
              </p>
              {paymentDetails.eWallet.url && (
                <img src={paymentDetails.eWallet.url} alt="E-Wallet QR" />
              )}
            </>
          )}
          <p>
            <strong>Jumlah yang Harus Dibayar:</strong> {formatCurrency(amountWithMargin)}
          </p>
          <p>
            <strong>Status Pembayaran:</strong> {paymentStatus} {/* Updated field */}
          </p>
          <p>
            <strong>Tanggal Pembayaran:</strong> {userData.paidDate ? dayjs(userData.paidDate).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
          </p>
          <p>
            <strong>Settlement Date:</strong> {userData.settleDate ? dayjs(userData.settleDate).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
          </p>
        </>
      );
    }
  };

  return (
    <div className="confirmation-page">
      <div className="confirmation-wrapper">
        <div className="confirmation-alert">
          <h2
            style={{ color: "red" }}
            className="text-xl md:text-2xl font-semibold"
          >
            JANGAN TINGGALKAN HALAMAN INI SAMPAI PEMBAYARAN SELESAI DAN BERHASIL
          </h2>
        </div>

        <div className="flex flex-col items-start justify-center gap-2 pb-3">
          <h2 className="text-black text-xl">Konfirmasi Pembayaran</h2>
          <div className="confirmation-content">
            <div className="payment-info">
              <h4 className="pb-5 text-gray-600">Detail Pembayaran</h4>
              {renderPaymentDetails()}
            </div>
          </div>
        </div>

        <div className="buttons flex flex-row justify-start items-center gap-3">
          <Button
            onClick={handleBackToHome}
            className="black text-xs sm:text-base"
          >
            Kembali ke Home
          </Button>
          <Button
            id="btn-konfirmasi-pembayaran"
            onClick={handleCheckStatus}
            className="black text-xs sm:text-base"
          >
            Konfirmasi Payment
          </Button>
        </div>
      </div>
      <div className="user-detail text-black">
        <div className="detail-header">
          <h1 className="text-black text-xl md:text-3xl pb-4">
            Ringkasan Pesanan
          </h1>
          <span className="text-gray-600">
            <strong className="text-black">Transaction ID: </strong>
            {userData.transaction_id}
          </span>
        </div>
        <div className="detail-time text-gray-600">
          <CIcon icon={cilAvTimer} />
          <span style={{ marginLeft: 10 }}>
            Pay before <strong className="text-black">{expiryTime}</strong>
          </span>
        </div>
        {expiryTime && dayjs().isAfter(expiryTime) && (
          <p style={{ color: "red" }}>The payment link has expired.</p>
        )}
        <div className="user-info">
          <h4 className="text-lg md:text-xl pb-3">Informasi Pengguna</h4>
          <div className="flex flex-col items-start justify-center gap-1">
            <p>
              <strong>Status:</strong> {userData.status}
            </p>
            <p>
              <strong>Phone Number:</strong> {userData.phone_number}
            </p>
            <p>
              <strong>Amount:</strong> {formatCurrency(userData.amount)}
            </p>
            <p>
              <strong>Ref ID:</strong> {userData.ref_id}
            </p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ConfirmationTopUp;
