import React from 'react'
import HeaderTwo from '../../components/header/HeaderTwo'
import FaQ from './FaQ'
import Footer from '../../components/footer/Footer'
import FAQBreadcrumb from './FAQBreadcrumb'

function FaQDetail() {
  return (
    <>
    <HeaderTwo/>
    <main>
      <FAQBreadcrumb/>
        <FaQ/>
    </main>
    <Footer/>
    </>
  )
}

export default FaQDetail