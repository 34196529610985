import React from 'react'
import Spage from './Spage'

function SpageDetails() {
  return (
    <div>
        <Spage/>
    </div>
  )
}

export default SpageDetails