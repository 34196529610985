import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser, cilLockUnlocked} from "@coreui/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import API from "../../api";

const Login = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    const username = event.target.username.value;
    const password = event.target.password.value;
  
    const loginData = {
      username,
      password,
    };
  
    try {
      const response = await API.post("login", loginData);
      if (response.status === "Bad Request" || response.status === "Server Error") {
        if (response.errors) {
          response.errors.forEach((val) => {
            toast.error(val.msg, { position: "top-right" });
          });
        } else {
          toast.error(response.message, { position: "top-right" });
        }
      } else {
        localStorage.setItem("token", response.token);
        localStorage.setItem("username", username); // Store username
        localStorage.setItem("user_id_nero", response.user_id); // Store user_id_nero

        // Check if there's a redirect URL stored in localStorage
        const redirectPath = localStorage.getItem('redirectAfterLogin');
        if (redirectPath) {
          localStorage.removeItem('redirectAfterLogin'); // Clear it after use
          history.push(redirectPath); // Redirect to the saved path
        } else {
          history.push("/profile"); // Default redirect if no saved path
        }
      }
    } catch (error) {
      toast.error("Login failed. Please try again.", { position: "top-right" });
    }
  };
  

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center mobile-login">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-2 sm:p-7 max-w-[29rem]">
                <CCardBody>
                  <CForm onSubmit={handleLogin}>
                    <h1 className="text-4xl font-medium pb-2">Login</h1>
                    <p className="text-body-secondary pb-3">
                    Login ke akun Anda
                    </p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        name="username"
                        placeholder="Username"
                        autoComplete="username"
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                      <CInputGroupText onClick={toggleShowPassword} style={{cursor: 'pointer'}}>
                        <CIcon icon={showPassword ? cilLockUnlocked :  cilLockLocked} />
                      </CInputGroupText>
                    </CInputGroup>
                    <CRow className="mt-10">
                      <CCol>
                        <CButton color="primary" className="px-4" type="submit">
                          Login
                        </CButton>
                      </CCol>
                      <CCol className="text-right">
                        <Link to="/forgot-password">
                          <CButton color="link" className="px-0 text-xs sm:text-base">
                            Forgot password?
                          </CButton>
                        </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white bg-primary p-7 max-w-[29rem] width-100"
              >
                <CCardBody className="text-center">
                  <div>
                    <h2 style={{color:'white'}} className="text-4xl font-medium pb-2">Sign up</h2>
                    <p style={{color:"white"}}>
                    Bergabunglah sekarang untuk tetap unggul dan jangan lewatkan kesempatan untuk meningkatkan pengalaman bermain Anda!
                    </p>
                    <Link to="/register">
                      <CButton
                        color="primary"
                        className="mt-3"
                        active
                        tabIndex={-1}
                        style={{color:'black', backgroundColor:'#FFC700', borderColor:'white'}}
                      >
                        Register Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
      <ToastContainer />
    </div>
  );
};

export default Login;
