import React from 'react'
import PaymentsBreadCrumb from './PaymentsBreadCrumb'
import Payments from './Payments'

function PaymentsDetail() {
  return (
    <>
    
    <PaymentsBreadCrumb/>
    <Payments/>
    </>
  )
}

export default PaymentsDetail