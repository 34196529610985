import React, { useState, useEffect } from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CFormSelect,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';

import '@coreui/coreui/dist/css/coreui.min.css';
import API from '../../api';
import { cilLockLocked, cilUser, cilLockUnlocked, cilCommentSquare, cilPhone } from '@coreui/icons';

const RegisterRE = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [recoveryQuestions, setRecoveryQuestions] = useState([]);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const initialValues = {
    username: '',
    password: '',
    confirmPassword: '',
    recovery_question: '',
    recovery_answer: '',
    nik: '',
    phone_number: '',
  };

  useEffect(() => {
    retrieveQuestions();
  }, []);

  const validationSchema = Yup.object({
    username: Yup.string().required('Username is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required'),
    recovery_question: Yup.string().required('Recovery question is required'),
    recovery_answer: Yup.string().required('Recovery answer is required'),
    nik: Yup.string().required('NIK is required').matches(/^\d+$/, 'NIK must be a number').min(16, 'NIK must be 16 digits').max(16, 'NIK must be 16 digits'),
    phone_number: Yup.string().required('Phone number is required').matches(/^\d+$/, 'Phone number must be a number'),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    let dto = {
      ...values,
    };
    delete dto.confirmPassword; // we don't need this 

    const response = await API.post('register', dto);
    if (response.status === 'Bad Request' || response.status === 'Server Error') {
      if (response.errors) {
        response.errors.forEach((val) => {
          toast.error(val.msg, {
            position: 'top-right',
          });
        });
      } else {
        toast.error(response.message, {
          position: 'top-right',
        });
      }
    } else {
      if (response.role.toLowerCase() === 'user') {
        localStorage.setItem('token', response.token);
        localStorage.setItem('username', dto.username); // Save the username to local storage
        history.push('/profile');
      } else {
        toast.error('Access denied: Only Users can log in', {
          position: 'top-right',
        });
      }
    }
  };

  const retrieveQuestions = async () => {
    try {
      const response = await API.get('questions');
      if (response.status === 'Bad Request' || response.status === 'Server Error') {
        if (response.errors) {
          response.errors.forEach((val) => {
            toast.error(val.msg, {
              position: 'top-right',
            });
          });
        } else {
          toast.error(response.message, {
            position: 'top-right',
          });
        }
      } else {
        setRecoveryQuestions(response.data);
      }
    } catch (error) {
      toast.error(error.message || 'An error occurred', {
        position: 'top-right',
      });
    }
  };

  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center p-10">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                  {({ isSubmitting }) => (
                    <Form>
                      <h1>Register Reseller</h1>
                      <p className="text-body-secondary">Create your account</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <Field name="username" as={CFormInput} placeholder="Username" autoComplete="username" />
                      </CInputGroup>
                      <ErrorMessage name="username" component="div" className="text-danger mb-3" />
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilCommentSquare} />
                        </CInputGroupText>
                        <Field name="nik" as={CFormInput} placeholder="NIK" autoComplete="nik" />
                      </CInputGroup>
                      <ErrorMessage name="nik" component="div" className="text-danger mb-3" />
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilPhone} />
                        </CInputGroupText>
                        <Field name="phone_number" as={CFormInput} placeholder="Phone Number" autoComplete="phone-number" />
                      </CInputGroup>
                      <ErrorMessage name="phone_number" component="div" className="text-danger mb-3" />
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <Field name="password" as={CFormInput} type={showPassword ? "text" : "password"} placeholder="Password" autoComplete="new-password" />
                        <CInputGroupText onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
                          <CIcon icon={showPassword ? cilLockUnlocked : cilLockLocked} />
                        </CInputGroupText>
                      </CInputGroup>
                      <ErrorMessage name="password" component="div" className="text-danger mb-3" />
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <Field name="confirmPassword" as={CFormInput} type={showConfirmPassword ? "text" : "password"} placeholder="Repeat password" autoComplete="new-password" />
                        <CInputGroupText onClick={toggleShowConfirmPassword} style={{ cursor: 'pointer' }}>
                          <CIcon icon={showConfirmPassword ? cilLockUnlocked : cilLockLocked} />
                        </CInputGroupText>
                      </CInputGroup>
                      <ErrorMessage name="confirmPassword" component="div" className="text-danger mb-3" />
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilCommentSquare} />
                        </CInputGroupText>
                        <Field name="recovery_question" as={CFormSelect} autoComplete="recovery-question">
                          <option value="">Select a recovery question...</option>
                          {recoveryQuestions.map((question, index) => (
                            <option key={index} value={question.id}>{question.text}</option>
                          ))}
                        </Field>
                      </CInputGroup>
                      <ErrorMessage name="recovery_question" component="div" className="text-danger mb-3" />
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilCommentSquare} />
                        </CInputGroupText>
                        <Field name="recovery_answer" as={CFormInput} placeholder="Recovery Answer" autoComplete="recovery-answer" />
                      </CInputGroup>
                      <ErrorMessage name="recovery_answer" component="div" className="text-danger mb-3" />
                      <div className="d-grid">
                        <CButton color="success" type="submit" disabled={isSubmitting}>
                          Create Account
                        </CButton>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
      <ToastContainer />
    </div>
  );
};

export default RegisterRE;
