// src/config/productsConfig.js

import * as Yup from "yup";

const productsConfig = {
  "Mobile Legends": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Zone ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "zoneID", label: "Zone ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Diamonds yang kamu inginkan ",
      "Masukkan User ID dan Zone ID Anda, Contoh : 1234567 (1234)",
      "Selesaikan pembayaran",
      "Diamonds akan ditambahkan ke akun Mobile Legends kamu",
    ],
  },
  "PUBG Mobile (Indonesia)": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },
  "PUBG Mobile (Global)": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },
  
  "AFK Journey": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },

  "Arena Of Valor (No Bind Garena)": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("Open ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Open ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },
  "Call of Duty Mobile (Bind FB)": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("Player ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Player ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },
  "Onmyoji Arena": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },
  "Undawn (Bind Garena)": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },
  "Age of Empires Mobile": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Zone is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
          { value: "Realm001", label: "Realm001" },
          { value: "Realm002", label: "Realm002" },
          { value: "Kingdom001", label: "Kingdom001" },
          { value: "Kingdom002", label: "Kingdom002" },
          { value: "Kingdom003", label: "Kingdom003" },
          { value: "Kingdom004", label: "Kingdom004" },
          { value: "Kingdom005", label: "Kingdom005" },
          { value: "Kingdom006", label: "Kingdom006" },
          { value: "Kingdom007", label: "Kingdom007" },
          { value: "Kingdom008", label: "Kingdom008" },
          { value: "Kingdom009", label: "Kingdom009" },
          { value: "Kingdom010", label: "Kingdom010" },
          { value: "Empire #001", label: "Empire #001" },
          { value: "Empire #002", label: "Empire #002" },
          { value: "Empire #003", label: "Empire #003" },
          { value: "Empire #004", label: "Empire #004" },
          { value: "Empire #005", label: "Empire #005" },
          { value: "Empire #006", label: "Empire #006" },
          { value: "Empire #007", label: "Empire #007" },
          { value: "Empire #008", label: "Empire #008" },
          { value: "Empire #009", label: "Empire #009" },
          { value: "Empire #010", label: "Empire #010" },
          { value: "Empire #011", label: "Empire #011" },
          { value: "Empire #012", label: "Empire #012" },
          { value: "Empire #013", label: "Empire #013" },
          { value: "Empire #014", label: "Empire #014" },
          { value: "Empire #015", label: "Empire #015" },
          { value: "Empire #016", label: "Empire #016" },
          { value: "Empire #017", label: "Empire #017" },
          { value: "Empire #018", label: "Empire #018" },
          { value: "Empire #019", label: "Empire #019" },
          { value: "Empire #020", label: "Empire #020" },
          { value: "Empire #021", label: "Empire #021" },
          { value: "Empire #022", label: "Empire #022" },
          { value: "Empire #023", label: "Empire #023" },
          { value: "Empire #024", label: "Empire #024" },
          { value: "Empire #025", label: "Empire #025" },
          { value: "Empire #026", label: "Empire #026" },
          { value: "Empire #027", label: "Empire #027" },
          { value: "Empire #028", label: "Empire #028" },
          { value: "Empire #029", label: "Empire #029" },
          { value: "Empire #030", label: "Empire #030" },
          { value: "Empire #031", label: "Empire #031" },
          { value: "Empire #032", label: "Empire #032" },
          { value: "Empire #033", label: "Empire #033" },
          { value: "Empire #034", label: "Empire #034" },
          { value: "Empire #035", label: "Empire #035" },
          { value: "Empire #036", label: "Empire #036" },
          { value: "Empire #037", label: "Empire #037" },
          { value: "Empire #038", label: "Empire #038" },
          { value: "Empire #039", label: "Empire #039" },
          { value: "Empire #040", label: "Empire #040" },
          { value: "Empire #041", label: "Empire #041" },
          { value: "Empire #042", label: "Empire #042" },
          { value: "Empire #043", label: "Empire #043" },
          { value: "Empire #044", label: "Empire #044" },
          { value: "Empire #045", label: "Empire #045" },
          { value: "Empire #046", label: "Empire #046" },
          { value: "Empire #047", label: "Empire #047" },
          { value: "Empire #048", label: "Empire #048" },
          { value: "Empire #049", label: "Empire #049" },
          { value: "Empire #050", label: "Empire #050" },
          { value: "Empire #051", label: "Empire #051" },
          { value: "Empire #052", label: "Empire #052" },
          { value: "Empire #053", label: "Empire #053" },
          { value: "Empire #054", label: "Empire #054" },
          { value: "Empire #055", label: "Empire #055" },
          { value: "Empire #056", label: "Empire #056" },
          { value: "Empire #057", label: "Empire #057" },
          { value: "Empire #058", label: "Empire #058" },
          { value: "Empire #059", label: "Empire #059" },
          { value: "Empire #060", label: "Empire #060" },
          { value: "Empire #061", label: "Empire #061" },
          { value: "Empire #062", label: "Empire #062" },
          { value: "Empire #063", label: "Empire #063" },
          { value: "Empire #064", label: "Empire #064" },
          { value: "Empire #065", label: "Empire #065" },
          { value: "Empire #066", label: "Empire #066" },
          { value: "Empire #067", label: "Empire #067" },
          { value: "Empire #068", label: "Empire #068" },
          { value: "Empire #069", label: "Empire #069" },
          { value: "Empire #070", label: "Empire #070" },
          { value: "Empire #071", label: "Empire #071" },
          { value: "Empire #072", label: "Empire #072" },
          { value: "Empire #073", label: "Empire #073" },
          { value: "Empire #074", label: "Empire #074" },
          { value: "Empire #075", label: "Empire #075" },
          { value: "Empire #076", label: "Empire #076" },
          { value: "Empire #077", label: "Empire #077" },
          { value: "Empire #078", label: "Empire #078" },
          { value: "Empire #079", label: "Empire #079" },
          { value: "Empire #080", label: "Empire #080" },
          { value: "Empire #081", label: "Empire #081" },
          { value: "Empire #082", label: "Empire #082" },
          { value: "Empire #083", label: "Empire #083" },
          { value: "Empire #084", label: "Empire #084" },
          { value: "Empire #085", label: "Empire #085" },
          { value: "Empire #086", label: "Empire #086" },
          { value: "Empire #087", label: "Empire #087" },
          { value: "Empire #088", label: "Empire #088" },
          { value: "Empire #089", label: "Empire #089" },
          { value: "Empire #090", label: "Empire #090" },
          { value: "Empire #091", label: "Empire #091" },
          { value: "Empire #092", label: "Empire #092" },
          { value: "Empire #093", label: "Empire #093" },
          { value: "Empire #094", label: "Empire #094" },
          { value: "Empire #095", label: "Empire #095" },
          { value: "Empire #096", label: "Empire #096" },
          { value: "Empire #097", label: "Empire #097" },
          { value: "Empire #098", label: "Empire #098" },
          { value: "Empire #099", label: "Empire #099" },
          { value: "Empire #100", label: "Empire #100" },
          { value: "Empire #101", label: "Empire #101" },
          { value: "Empire #102", label: "Empire #102" },
          { value: "Empire #103", label: "Empire #103" },
          { value: "Empire #104", label: "Empire #104" },
          { value: "Empire #105", label: "Empire #105" },
          { value: "Empire #106", label: "Empire #106" },
          { value: "Empire #107", label: "Empire #107" },
          { value: "Empire #108", label: "Empire #108" },
          { value: "Empire #109", label: "Empire #109" },
          { value: "Empire #110", label: "Empire #110" },
          { value: "Empire #111", label: "Empire #111" },
          { value: "Empire #112", label: "Empire #112" },
          { value: "Empire #113", label: "Empire #113" },
          { value: "Empire #114", label: "Empire #114" },
          { value: "Empire #115", label: "Empire #115" },
          { value: "Empire #116", label: "Empire #116" },
          { value: "Empire #117", label: "Empire #117" },
          { value: "Empire #118", label: "Empire #118" },
          { value: "Empire #119", label: "Empire #119" },
          { value: "Empire #120", label: "Empire #120" },
          { value: "Empire #121", label: "Empire #121" },
          { value: "Empire #122", label: "Empire #122" },
          { value: "Empire #123", label: "Empire #123" },
          { value: "Empire #124", label: "Empire #124" },
          { value: "Empire #125", label: "Empire #125" },
          { value: "Empire #126", label: "Empire #126" },
          { value: "Empire #127", label: "Empire #127" },
          { value: "Empire #128", label: "Empire #128" },
          { value: "Empire #129", label: "Empire #129" },
          { value: "Empire #130", label: "Empire #130" },
          { value: "Empire #131", label: "Empire #131" },
          { value: "Empire #132", label: "Empire #132" },
          { value: "Empire #133", label: "Empire #133" },
          { value: "Empire #134", label: "Empire #134" },
          { value: "Empire #135", label: "Empire #135" },
          { value: "Empire #136", label: "Empire #136" },
          { value: "Empire #137", label: "Empire #137" },
          { value: "Empire #138", label: "Empire #138" },
          { value: "Empire #139", label: "Empire #139" },
          { value: "Empire #140", label: "Empire #140" },
          { value: "Empire #141", label: "Empire #141" },
          { value: "Empire #142", label: "Empire #142" },
          { value: "Empire #143", label: "Empire #143" },
          { value: "Empire #144", label: "Empire #144" },
          { value: "Empire #145", label: "Empire #145" },
          { value: "Empire #146", label: "Empire #146" },
          { value: "Empire #147", label: "Empire #147" },
          { value: "Empire #148", label: "Empire #148" },
          { value: "Empire #149", label: "Empire #149" },
          { value: "Empire #150", label: "Empire #150" },
          { value: "Empire #151", label: "Empire #151" },
          { value: "Empire #152", label: "Empire #152" },
          { value: "Empire #153", label: "Empire #153" },
          { value: "Empire #154", label: "Empire #154" },
          { value: "Empire #155", label: "Empire #155" },
          { value: "Empire #156", label: "Empire #156" },
          { value: "Empire #157", label: "Empire #157" },
          { value: "Empire #158", label: "Empire #158" },
          { value: "Empire #159", label: "Empire #159" },
          { value: "Empire #160", label: "Empire #160" },
          { value: "Empire #161", label: "Empire #161" },
          { value: "Empire #162", label: "Empire #162" },
          { value: "Empire #163", label: "Empire #163" },
          { value: "Empire #164", label: "Empire #164" },
          { value: "Empire #165", label: "Empire #165" },
          { value: "Empire #166", label: "Empire #166" },
          { value: "Empire #167", label: "Empire #167" },
          { value: "Empire #168", label: "Empire #168" },
          { value: "Empire #169", label: "Empire #169" },
          { value: "Empire #170", label: "Empire #170" },
          { value: "Empire #171", label: "Empire #171" },
          { value: "Empire #172", label: "Empire #172" },
          { value: "Empire #173", label: "Empire #173" },
          { value: "Empire #174", label: "Empire #174" },
          { value: "Empire #175", label: "Empire #175" },
          { value: "Empire #176", label: "Empire #176" },
          { value: "Empire #177", label: "Empire #177" },
          { value: "Empire #178", label: "Empire #178" },
          { value: "Empire #179", label: "Empire #179" },
          { value: "Empire #180", label: "Empire #180" },
          { value: "Empire #181", label: "Empire #181" },
          { value: "Empire #182", label: "Empire #182" },
          { value: "Empire #183", label: "Empire #183" },
          { value: "Empire #184", label: "Empire #184" },
          { value: "Empire #185", label: "Empire #185" },
          { value: "Empire #186", label: "Empire #186" },
          { value: "Empire #187", label: "Empire #187" },
          { value: "Empire #188", label: "Empire #188" },
          { value: "Empire #189", label: "Empire #189" },
          { value: "Empire #190", label: "Empire #190" },
          { value: "Empire #191", label: "Empire #191" },
          { value: "Empire #192", label: "Empire #192" },
          { value: "Empire #193", label: "Empire #193" },
          { value: "Empire #194", label: "Empire #194" },
          { value: "Empire #195", label: "Empire #195" },
          { value: "Empire #196", label: "Empire #196" },
          { value: "Empire #197", label: "Empire #197" },
          { value: "Empire #198", label: "Empire #198" },
          { value: "Empire #199", label: "Empire #199" },
          { value: "Empire #200", label: "Empire #200" }
        ]
      },
      
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },
  "Free Fire": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },
  "Arena Breakout": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Open ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },
  "Ace Racer": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server",
        type: "select",
        options: [
          { value: "JAPAN", label: "JAPAN" },
          { value: "KOREA", label: "KOREA" },
          { value: "ASIA", label: "ASIA" },
          { value: "NORTH-AMERICA", label: "NORTH-AMERICA" },
          { value: "EUROPE", label: "EUROPE" },
        ],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Produk yang kamu inginkan",
      "Masukkan User ID, Server Anda",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan ke akun Ace Racer kamu",
    ],
  },
  "Identity V": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
          { value: "Asia", label: "Asia" },
          { value: "NA", label: "NA" },
          { value: "EU", label: "EU" },
        ],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Produk yang kamu inginkan",
      "Masukkan User ID, Server Anda",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan ke akun Identity V kamu",
    ],
  },
  "Arena Of Valor": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("Open ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Open ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },
  "Atlantica Online": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "ID Player", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },
  Badlanders: {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server",
        type: "select",
        options: [{ value: "Global", label: "Global" }],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },
  "Black Clover M": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },
  "Blood Strike": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [{ value: "Global", label: "Global" }],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },
  "Brawl Stars": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Tag Name", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },
  "Boyaa Domino Qiu Qiu": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "ID Player", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang anda inginkan.",
      "Masukkan ID anda ",
      "Selesaikan Pembayaran",
      "Produk akan ditambahkan pada akun anda.",
    ],
  },
  "Call of Duty Mobile": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("Open ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Open ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih produk yang kamu inginkan ",
      "Masukkan Open ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Call of Duty Mobile kamu",
    ],
  },

  "Captain Tsubasa: Ace": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Nickname is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "DeNa ID", type: "text" },
      { name: "zoneID", label: "Nickname", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Captain Tsubasa: Ace kamu",
    ],
  },

  "Clash of Clans": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("Player Tag is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Player TAG", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Clash of clans kamu",
    ],
  },

  "Clash Royale": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("Player TAG is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Player TAG", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Clash of clans kamu",
    ],
  },

  "Cloud Song: Saga of Skywalkers": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Zone ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Role ID", type: "text" },
      { name: "zoneID", label: "Zone ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Cloud Song: Saga of Skywalkers kamu",
    ],
  },

  "Dragon POW!": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Dragon POW! kamu",
    ],
  },

  "Dragon Raja (SEA)": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Dragon Raja (SEA) kamu",
    ],
  },

  "Eggy Party": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Zone ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "zoneID", label: "Server ID", type: "select", options: [{ value: "Asia", label: "Asia" }] },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Eggy Party kamu",
    ],
  },

  "Farlight 84": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Farlight 84 kamu",
    ],
  },

  "Genshin Impact": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
          { value: "os_usa", label: "America" },
          { value: "os_euro", label: "Europe" },
          { value: "os_asia", label: "Asia" },
          { value: "os_cht", label: "TW, HK, MO" },
        ],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Genshin Impact kamu",
    ],
  },

  Growtopia: {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Growtopia kamu",
    ],
  },

  "Harry Potter: Magic Awakened": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
          { value: "萬應室", label: "萬應室" },
      { value: "不死鳥", label: "不死鳥" },
      { value: "ユニコーン", label: "ユニコーン" },
      { value: "니플러", label: "니플러" },
      { value: "Ashwinder", label: "Ashwinder" },
      { value: "Erumpent", label: "Erumpent" },
      { value: "Rougarou", label: "Rougarou" },
      { value: "驺吾(海外加速区）", label: "驺吾(海外加速区）" }
        ],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Harry Potter: Magic Awakened kamu",
    ],
  },

  Hago: {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Hago kamu",
    ],
  },

  "Honkai Impact 3": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Honkai Impact 3 kamu",
    ],
  },

  "Honkai: Star Rail": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
          { value: "prod_official_asia", label: "Asia" },
          { value: "prod_official_usa", label: "America" },
          { value: "prod_official_eur", label: "Europe" },
          { value: "prod_official_cht", label: "TW, HK, MO" },
        ],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Honkai: Star Rail kamu",
    ],
  },

  "Honor of Kings": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Honor of Kings kamu",
    ],
  },

  "Laplace M": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Spirals yang kamu inginkan ",
      "Masukkan ID Karakter Anda, Contoh : 500404018",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Laplace M kamu",
    ],
  },

  "League of Legends: Wild Rift": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Points yang kamu inginkan ",
      "Masukkan Riot ID Anda, Contoh : Nerogaming#1234",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun League of Legends: WIld Rift kamu",
    ],
  },

  "League of Legends - PC": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Points yang kamu inginkan ",
      "Masukkan Riot ID Anda, Contoh : Nerogaming#1234",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun League of Legends - PC kamu",
    ],
  },

  LifeAfter: {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
      { value: "Asia-Pasific", label: "Asia-Pasific" },
      { value: "Europe", label: "Europe" },
      { value: "North America", label: "North America" },
      { value: "Ocenia", label: "Ocenia" },
      { value: "SouthAmerica", label: "SouthAmerica" },
      { value: "NA", label: "NA" },
      { value: "Asia", label: "Asia" },
      { value: "MiskaTown (NA)", label: "MiskaTown (NA)" },
      { value: "SandCastle (NA)", label: "SandCastle (NA)" },
      { value: "MouthSwamp (NA)", label: "MouthSwamp (NA)" },
      { value: "RedwoodTown (NA)", label: "RedwoodTown (NA)" },
      { value: "Obelisk (NA)", label: "Obelisk (NA)" },
      { value: "FallForest (AU)", label: "FallForest (AU)" },
      { value: "MountSnow (AU)", label: "MountSnow (AU)" },
      { value: "NancyCity (SEA)", label: "NancyCity (SEA)" },
      { value: "CharlesTown (SEA)", label: "CharlesTown (SEA)" },
      { value: "SnowHighlands (SEA)", label: "SnowHighlands (SEA)" },
      { value: "Santopany (SEA)", label: "Santopany (SEA)" },
      { value: "LevinCity (SEA)", label: "LevinCity (SEA)" },
      { value: "NewLand (NA)", label: "NewLand (NA)" },
      { value: "MileStone (SEA)", label: "MileStone (SEA)" },
      { value: "ChaosOutpost (NA)", label: "ChaosOutpost (NA)" },
      { value: "ChaosCity (SEA)", label: "ChaosCity (SEA)" },
      { value: "TwinIslands (SEA)", label: "TwinIslands (SEA)" },
      { value: "HopeWall (SEA)", label: "HopeWall (SEA)" },
      { value: "IronStride (NA)", label: "IronStride (NA)" },
      { value: "CrystalthornSea", label: "CrystalthornSea" },
      { value: "LabyrinthSea", label: "LabyrinthSea" }
    ],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun LifeAfter kamu",
    ],
  },

  "Light of Thel: New Era": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Light of Thel: New Era kamu",
    ],
  },

  Lita: {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Lita kamu",
    ],
  },

  "Love and Deepspace": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Love and Deepspace kamu",
    ],
  },

  "MARVEL SNAP": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("Snap ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Snap ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan Snap ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun MARVEL SNAP kamu",
    ],
  },

  "Metal Slug: Awakening": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Metal Slug: Awakening kamu",
    ],
  },

  "MU Origin 3 (SEA)": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("Role ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Role ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun MU Origin 3 (SEA) kamu",
    ],
  },

  "Moonlight Blade M": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("Character ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Moonlight Blade M kamu",
    ],
  },

  "NBA INFINITE": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
      { value: "Asia-Pasific", label: "Asia-Pasific" },
      { value: "Europe", label: "Europe" },
      { value: "North America", label: "North America" },
      { value: "Ocenia", label: "Ocenia" },
      { value: "SouthAmerica", label: "SouthAmerica" },
      { value: "NA", label: "NA" },
      { value: "Asia", label: "Asia" }
    ],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun NBA INFINITE kamu",
    ],
  },

  "New State Mobile (NC)": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun New State Mobile (NC) kamu",
    ],
  },

  "One Punch Man: The Strongest": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Zone ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "zoneID", label: "Zone ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun One Punch Man: The Strongest kamu",
    ],
  },

  "Pixel Gun 3D": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Pixel Gun 3D kamu",
    ],
  },

  "Punishing: Gray Raven": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("Role ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Role ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
      { value: "Asia-Pasific", label: "Asia-Pasific" },
      { value: "Europe", label: "Europe" },
      { value: "North America", label: "North America" }
    ],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Punishing: Gray Raven kamu",
    ],
  },

  "Ragnarok Origin": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("Secret Code ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
          { value: "Prontera-1", label: "Prontera-1" },
          { value: "Prontera-2", label: "Prontera-2" },
          { value: "Prontera-3", label: "Prontera-3" },
          { value: "Prontera-4", label: "Prontera-4" },
          { value: "Prontera-5", label: "Prontera-5" },
          { value: "Prontera-6", label: "Prontera-6" },
          { value: "Prontera-7", label: "Prontera-7" },
          { value: "Prontera-8", label: "Prontera-8" },
          { value: "Prontera-9", label: "Prontera-9" },
          { value: "Prontera-10", label: "Prontera-10" },
          { value: "Izlude-1", label: "Izlude-1" },
          { value: "Izlude-2", label: "Izlude-2" },
          { value: "Izlude-3", label: "Izlude-3" },
          { value: "Izlude-4", label: "Izlude-4" },
          { value: "Izlude-5", label: "Izlude-5" },
          { value: "Izlude-6", label: "Izlude-6" },
          { value: "Izlude-7", label: "Izlude-7" },
          { value: "Izlude-8", label: "Izlude-8" },
          { value: "Izlude-9", label: "Izlude-9" },
          { value: "Izlude-10", label: "Izlude-10" },
          { value: "Morroc-1", label: "Morroc-1" },
          { value: "Morroc-2", label: "Morroc-2" },
          { value: "Morroc-3", label: "Morroc-3" },
          { value: "Morroc-4", label: "Morroc-4" },
          { value: "Morroc-5", label: "Morroc-5" },
          { value: "Morroc-6", label: "Morroc-6" },
          { value: "Morroc-7", label: "Morroc-7" },
          { value: "Morroc-8", label: "Morroc-8" },
          { value: "Morroc-9", label: "Morroc-9" },
          { value: "Morroc-10", label: "Morroc-10" },
          { value: "Geffen-1", label: "Geffen-1" },
          { value: "Geffen-2", label: "Geffen-2" },
          { value: "Geffen-3", label: "Geffen-3" },
          { value: "Geffen-4", label: "Geffen-4" },
          { value: "Geffen-5", label: "Geffen-5" },
          { value: "Geffen-6", label: "Geffen-6" },
          { value: "Geffen-7", label: "Geffen-7" },
          { value: "Geffen-8", label: "Geffen-8" },
          { value: "Geffen-9", label: "Geffen-9" },
          { value: "Geffen-10", label: "Geffen-10" },
          { value: "Payon-1", label: "Payon-1" },
          { value: "Payon-2", label: "Payon-2" },
          { value: "Payon-3", label: "Payon-3" },
          { value: "Payon-4", label: "Payon-4" },
          { value: "Payon-5", label: "Payon-5" },
          { value: "Payon-6", label: "Payon-6" },
          { value: "Payon-7", label: "Payon-7" },
          { value: "Payon-8", label: "Payon-8" },
          { value: "Payon-9", label: "Payon-9" },
          { value: "Payon-10", label: "Payon-10" },
          { value: "Poring Island-1", label: "Poring Island-1" },
          { value: "Poring Island-2", label: "Poring Island-2" },
          { value: "Poring Island-3", label: "Poring Island-3" },
          { value: "Poring Island-4", label: "Poring Island-4" },
          { value: "Poring Island-5", label: "Poring Island-5" },
          { value: "Poring Island-6", label: "Poring Island-6" },
          { value: "Poring Island-7", label: "Poring Island-7" },
          { value: "Poring Island-8", label: "Poring Island-8" },
          { value: "Poring Island-9", label: "Poring Island-9" },
          { value: "Poring Island-10", label: "Poring Island-10" },
          { value: "Orc Village-1", label: "Orc Village-1" },
          { value: "Orc Village-2", label: "Orc Village-2" },
          { value: "Orc Village-3", label: "Orc Village-3" },
          { value: "Orc Village-4", label: "Orc Village-4" },
          { value: "Orc Village-5", label: "Orc Village-5" },
          { value: "Orc Village-6", label: "Orc Village-6" },
          { value: "Orc Village-7", label: "Orc Village-7" },
          { value: "Orc Village-8", label: "Orc Village-8" },
          { value: "Orc Village-9", label: "Orc Village-9" },
          { value: "Orc Village-10", label: "Orc Village-10" },
          { value: "Shipwreck-1", label: "Shipwreck-1" },
          { value: "Shipwreck-2", label: "Shipwreck-2" },
          { value: "Shipwreck-3", label: "Shipwreck-3" },
          { value: "Shipwreck-4", label: "Shipwreck-4" },
          { value: "Shipwreck-5", label: "Shipwreck-5" },
          { value: "Shipwreck-6", label: "Shipwreck-6" },
          { value: "Shipwreck-7", label: "Shipwreck-7" },
          { value: "Shipwreck-8", label: "Shipwreck-8" },
          { value: "Shipwreck-9", label: "Shipwreck-9" },
          { value: "Memoria", label: "Memoria" },
          { value: "Ant Hell-1", label: "Ant Hell-1" },
          { value: "Ant Hell-2", label: "Ant Hell-2" },
          { value: "Ant Hell-3", label: "Ant Hell-3" },
          { value: "Ant Hell-4", label: "Ant Hell-4" },
          { value: "Ant Hell-5", label: "Ant Hell-5" },
          { value: "Ant Hell-6", label: "Ant Hell-6" },
          { value: "Ant Hell-7", label: "Ant Hell-7" },
          { value: "Ant Hell-8", label: "Ant Hell-8" },
          { value: "Ant Hell-9", label: "Ant Hell-9" },
          { value: "Ant Hell-10", label: "Ant Hell-10" },
          { value: "Awakening", label: "Awakening" },
          { value: "Goblin Forest-1", label: "Goblin Forest-1" },
          { value: "Goblin Forest-2", label: "Goblin Forest-2" },
          { value: "Valentine", label: "Valentine" },
          { value: "Goblin Forest-4", label: "Goblin Forest-4" },
          { value: "Lasagna", label: "Lasagna" },
          { value: "1st-Anniversary", label: "1st-Anniversary" },
        ],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan Secret Code",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Ragnarok Origin kamu",
    ],
  },

  "Ragnarok Origin Special Pack": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Zone ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "zoneID", label: "Zone ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Ragnarok Origin Special Pack kamu",
    ],
  },

  "Ragnarok X: Next Generation": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
      { value: "Opera Phantom", label: "Opera Phantom" },
      { value: "Wing of Blessing", label: "Wing of Blessing" },
      { value: "Royal Instrument", label: "Royal Instrument" },
      { value: "Valhalla", label: "Valhalla" },
      { value: "Gungnir", label: "Gungnir" },
      { value: "Central Plains", label: "Central Plains" },
      { value: "Dark Lord", label: "Dark Lord" },
      { value: "Clock Tower", label: "Clock Tower" },
      { value: "Comodo", label: "Comodo" },
      { value: "Rainbow Paradise", label: "Rainbow Paradise" },
      { value: "Temple of Dawn", label: "Temple of Dawn" },
      { value: "Golden Lava", label: "Golden Lava" },
      { value: "Highland", label: "Highland" },
      { value: "Demons Castle", label: "Demons Castle" },
      { value: "Sealed Island", label: "Sealed Island" },
      { value: "Sipera", label: "Sipera" },
      { value: "Silent Ship", label: "Silent Ship" },
      { value: "Golden Route", label: "Golden Route" },
      { value: "Sapir", label: "Sapir" },
      { value: "Rose Red", label: "Rose Red" },
      { value: "Kingdom of Freedom", label: "Kingdom of Freedom" },
      { value: "Nicola", label: "Nicola" },
      { value: "Crystal Waterfall", label: "Crystal Waterfall" },
      { value: "Bifrost", label: "Bifrost" },
      { value: "Nastia", label: "Nastia" },
      { value: "Strouf Treasury", label: "Strouf Treasury" },
      { value: "Green Tranquil Pond", label: "Green Tranquil Pond" },
      { value: "Ingael", label: "Ingael" },
      { value: "Tome of Glory", label: "Tome of Glory" },
      { value: "Incense Pavilion", label: "Incense Pavilion" },
      { value: "Carew", label: "Carew" },
      { value: "Boulders and Horns", label: "Boulders and Horns" },
      { value: "Exquisite Pond", label: "Exquisite Pond" },
      { value: "Nemesis", label: "Nemesis" },
      { value: "Aldebaran", label: "Aldebaran" },
      { value: "Honor of Emperium", label: "Honor of Emperium" },
      { value: "Frenetic Land", label: "Frenetic Land" },
      { value: "Bright Lotus Pond", label: "Bright Lotus Pond" },
      { value: "Seocho Market", label: "Seocho Market" },
      { value: "Eudora", label: "Eudora" },
      { value: "Moonlit Temple", label: "Moonlit Temple" },
      { value: "Hvalueden Wood Ruins", label: "Hvalueden Wood Ruins" },
      { value: "Dungeon Throne", label: "Dungeon Throne" },
      { value: "Dimension Door", label: "Dimension Door" },
      { value: "Gunslingers Revenge", label: "Gunslingers Revenge" },
      { value: "Ayothaya", label: "Ayothaya" },
      { value: "Luzon", label: "Luzon" },
      { value: "Majapahit", label: "Majapahit" },
      { value: "Garden City", label: "Garden City" },
      { value: "Manila", label: "Manila" },
      { value: "Sukhothai", label: "Sukhothai" },
      { value: "Attack On Poring", label: "Attack On Poring" },
      { value: "Light of Umbala", label: "Light of Umbala" },
      { value: "Tossakan", label: "Tossakan" },
      { value: "Badang", label: "Badang" },
      { value: "Lapulapu", label: "Lapulapu" },
      { value: "Gatotkaca", label: "Gatotkaca" },
      { value: "Srikandi", label: "Srikandi" },
      { value: "Kumpakan", label: "Kumpakan" },
      { value: "Original Love", label: "Original Love" },
      { value: "Angeling", label: "Angeling" },
      { value: "Deviling", label: "Deviling" },
      { value: "Ghostring", label: "Ghostring" },
      { value: "Mastering", label: "Mastering" },
      { value: "Song Tử", label: "Song Tử" },
      { value: "Xử Nữ", label: "Xử Nữ" },
      { value: "Half Anniversary", label: "Half Anniversary" },
      { value: "Hero Association", label: "Hero Association" },
      { value: "Monster Association", label: "Monster Association" }
    ],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Ragnarok X: Next Generation kamu",
    ],
  },

  "RF Online Classic": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun RF Online Classic kamu",
    ],
  },

  "RF Remastered": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun RF Remastered kamu",
    ],
  },

  "RO Forever Love": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal RO-Cash yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun RO Forever Love kamu",
    ],
  },

  "SEAL M SEA": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("CS Code is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "CS Code", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
      { value: "Rascal Rabbit 1", label: "Rascal Rabbit 1" },
      { value: "Rascal Rabbit 2", label: "Rascal Rabbit 2" },
      { value: "Piya 1", label: "Piya 1" },
      { value: "Piya 2", label: "Piya 2" },
      { value: "Beanie 1", label: "Beanie 1" },
      { value: "Beanie 2", label: "Beanie 2" }
    ],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun SEAL M SEA kamu",
    ],
  },

  "Speed Drifters": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan ID Speed Drifters",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Speed Drifters kamu",
    ],
  },

  "State of Survival": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun State of Survival kamu",
    ],
  },

  "Teamfight Tactics Mobile": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("Riot ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Riot ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan ID Riot Anda, Contoh: Bukalapgaming#1234",
      "Selesaikan pembayaran",
      "Koin akan ditambahkan ke akun Teamfight Tactics Mobile kamu",
    ],
  },

  Tarisland: {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
      { value: "ASIA-1", label: "ASIA-1" },
      { value: "ASIA-2", label: "ASIA-2" },
      { value: "ASIA-3", label: "ASIA-3" },
      { value: "ASIA-4", label: "ASIA-4" },
      { value: "ASIA-5", label: "ASIA-5" },
      { value: "ASIA-6", label: "ASIA-6" },
      { value: "ASIA-7", label: "ASIA-7" },
      { value: "ASIA-8", label: "ASIA-8" },
      { value: "ASIA-9", label: "ASIA-9" },
      { value: "ASIA-10", label: "ASIA-10" },
      { value: "ASIA-11", label: "ASIA-11" },
      { value: "ASIA-12", label: "ASIA-12" },
      { value: "ASIA-13", label: "ASIA-13" },
      { value: "ASIA-14", label: "ASIA-14" },
      { value: "ASIA-15", label: "ASIA-15" },
      { value: "ASIA-16", label: "ASIA-16" },
      { value: "ASIA-17", label: "ASIA-17" },
      { value: "ASIA-18", label: "ASIA-18" },
      { value: "ASIA-19", label: "ASIA-19" },
      { value: "ASIA-20", label: "ASIA-20" },
      { value: "ASIA-21", label: "ASIA-21" },
      { value: "ASIA-22", label: "ASIA-22" },
      { value: "ASIA-23", label: "ASIA-23" },
      { value: "ASIA-24", label: "ASIA-24" },
      { value: "ASIA-25", label: "ASIA-25" },
      { value: "ASIA-26", label: "ASIA-26" },
      { value: "ASIA-27", label: "ASIA-27" },
      { value: "ASIA-28", label: "ASIA-28" },
      { value: "ASIA-29", label: "ASIA-29" },
      { value: "ASIA-30", label: "ASIA-30" },
      { value: "ASIA-31", label: "ASIA-31" },
      { value: "ASIA-32", label: "ASIA-32" },
      { value: "ASIA-33", label: "ASIA-33" },
      { value: "ASIA-34", label: "ASIA-34" },
      { value: "ASIA-35", label: "ASIA-35" },
      { value: "ASIA-36", label: "ASIA-36" },
      { value: "ASIA-37", label: "ASIA-37" },
      { value: "ASIA-38", label: "ASIA-38" },
      { value: "ASIA-39", label: "ASIA-39" },
      { value: "ASIA-40", label: "ASIA-40" },
      { value: "ASIA-41", label: "ASIA-41" },
      { value: "ASIA-42", label: "ASIA-42" },
      { value: "ASIA-43", label: "ASIA-43" },
      { value: "ASIA-44", label: "ASIA-44" },
      { value: "ASIA-45", label: "ASIA-45" },
      { value: "ASIA-46", label: "ASIA-46" },
      { value: "ASIA-47", label: "ASIA-47" },
      { value: "ASIA-48", label: "ASIA-48" },
      { value: "ASIA-49", label: "ASIA-49" },
      { value: "ASIA-50", label: "ASIA-50" },
      { value: "ASIA-51", label: "ASIA-51" },
      { value: "ASIA-52", label: "ASIA-52" },
      { value: "ASIA-53", label: "ASIA-53" },
      { value: "ASIA-54", label: "ASIA-54" },
      { value: "ASIA-55", label: "ASIA-55" },
      { value: "ASIA-56", label: "ASIA-56" },
      { value: "ASIA-57", label: "ASIA-57" },
      { value: "ASIA-58", label: "ASIA-58" },
      { value: "ASIA-59", label: "ASIA-59" },
      { value: "ASIA-60", label: "ASIA-60" },
      { value: "ASIA-61", label: "ASIA-61" },
      { value: "ASIA-62", label: "ASIA-62" },
      { value: "ASIA-63", label: "ASIA-63" },
      { value: "ASIA-64", label: "ASIA-64" },
      { value: "ASIA-65", label: "ASIA-65" },
      { value: "ASIA-66", label: "ASIA-66" },
      { value: "ASIA-67", label: "ASIA-67" },
      { value: "ASIA-68", label: "ASIA-68" },
      { value: "ASIA-69", label: "ASIA-69" },
      { value: "ASIA-70", label: "ASIA-70" },
      { value: "ASIA-71", label: "ASIA-71" },
      { value: "ASIA-72", label: "ASIA-72" },
      { value: "ASIA-73", label: "ASIA-73" },
      { value: "ASIA-74", label: "ASIA-74" },
      { value: "ASIA-75", label: "ASIA-75" },
      { value: "ASIA-76", label: "ASIA-76" },
      { value: "ASIA-77", label: "ASIA-77" },
      { value: "ASIA-78", label: "ASIA-78" },
      { value: "ASIA-79", label: "ASIA-79" },
      { value: "ASIA-80", label: "ASIA-80" },
      { value: "ASIA-81", label: "ASIA-81" },
      { value: "ASIA-82", label: "ASIA-82" },
      { value: "ASIA-83", label: "ASIA-83" },
      { value: "ASIA-84", label: "ASIA-84" },
      { value: "ASIA-85", label: "ASIA-85" },
      { value: "ASIA-86", label: "ASIA-86" },
      { value: "ASIA-87", label: "ASIA-87" },
      { value: "ASIA-88", label: "ASIA-88" },
      { value: "ASIA-89", label: "ASIA-89" },
      { value: "ASIA-90", label: "ASIA-90" },
      { value: "ASIA-91", label: "ASIA-91" },
      { value: "ASIA-92", label: "ASIA-92" },
      { value: "ASIA-93", label: "ASIA-93" },
      { value: "ASIA-94", label: "ASIA-94" },
      { value: "ASIA-95", label: "ASIA-95" },
      { value: "ASIA-96", label: "ASIA-96" },
      { value: "ASIA-97", label: "ASIA-97" },
      { value: "ASIA-98", label: "ASIA-98" },
      { value: "ASIA-99", label: "ASIA-99" },
      { value: "ASIA-100", label: "ASIA-100" },
      { value: "NA-1", label: "NA-1" },
      { value: "NA-2", label: "NA-2" },
      { value: "NA-3", label: "NA-3" },
      { value: "NA-4", label: "NA-4" },
      { value: "NA-5", label: "NA-5" },
      { value: "NA-6", label: "NA-6" },
      { value: "NA-7", label: "NA-7" },
      { value: "NA-8", label: "NA-8" },
      { value: "NA-9", label: "NA-9" },
      { value: "NA-10", label: "NA-10" },
      { value: "NA-11", label: "NA-11" },
      { value: "NA-12", label: "NA-12" },
      { value: "NA-13", label: "NA-13" },
      { value: "NA-14", label: "NA-14" },
      { value: "NA-15", label: "NA-15" },
      { value: "NA-16", label: "NA-16" },
      { value: "NA-17", label: "NA-17" },
      { value: "NA-18", label: "NA-18" },
      { value: "NA-19", label: "NA-19" },
      { value: "NA-20", label: "NA-20" },
      { value: "NA-21", label: "NA-21" },
      { value: "NA-22", label: "NA-22" },
      { value: "NA-23", label: "NA-23" },
      { value: "NA-24", label: "NA-24" },
      { value: "NA-25", label: "NA-25" },
      { value: "NA-26", label: "NA-26" },
      { value: "NA-27", label: "NA-27" },
      { value: "NA-28", label: "NA-28" },
      { value: "NA-29", label: "NA-29" },
      { value: "NA-30", label: "NA-30" },
      { value: "NA-31", label: "NA-31" },
      { value: "NA-32", label: "NA-32" },
      { value: "NA-33", label: "NA-33" },
      { value: "NA-34", label: "NA-34" },
      { value: "NA-35", label: "NA-35" },
      { value: "NA-36", label: "NA-36" },
      { value: "NA-37", label: "NA-37" },
      { value: "NA-38", label: "NA-38" },
      { value: "NA-39", label: "NA-39" },
      { value: "NA-40", label: "NA-40" },
      { value: "NA-41", label: "NA-41" },
      { value: "NA-42", label: "NA-42" },
      { value: "NA-43", label: "NA-43" },
      { value: "NA-44", label: "NA-44" },
      { value: "NA-45", label: "NA-45" },
      { value: "NA-46", label: "NA-46" },
      { value: "NA-47", label: "NA-47" },
      { value: "NA-48", label: "NA-48" },
      { value: "NA-49", label: "NA-49" },
      { value: "NA-50", label: "NA-50" },
      { value: "NA-51", label: "NA-51" },
      { value: "NA-52", label: "NA-52" },
      { value: "NA-53", label: "NA-53" },
      { value: "NA-54", label: "NA-54" },
      { value: "NA-55", label: "NA-55" },
      { value: "NA-56", label: "NA-56" },
      { value: "NA-57", label: "NA-57" },
      { value: "NA-58", label: "NA-58" },
      { value: "NA-59", label: "NA-59" },
      { value: "NA-60", label: "NA-60" },
      { value: "NA-61", label: "NA-61" },
      { value: "NA-62", label: "NA-62" },
      { value: "NA-63", label: "NA-63" },
      { value: "NA-64", label: "NA-64" },
      { value: "NA-65", label: "NA-65" },
      { value: "NA-66", label: "NA-66" },
      { value: "NA-67", label: "NA-67" },
      { value: "NA-68", label: "NA-68" },
      { value: "NA-69", label: "NA-69" },
      { value: "NA-70", label: "NA-70" },
      { value: "NA-71", label: "NA-71" },
      { value: "NA-72", label: "NA-72" },
      { value: "NA-73", label: "NA-73" },
      { value: "NA-74", label: "NA-74" },
      { value: "NA-75", label: "NA-75" },
      { value: "NA-76", label: "NA-76" },
      { value: "NA-77", label: "NA-77" },
      { value: "NA-78", label: "NA-78" },
      { value: "NA-79", label: "NA-79" },
      { value: "NA-80", label: "NA-80" },
      { value: "NA-81", label: "NA-81" },
      { value: "NA-82", label: "NA-82" },
      { value: "NA-83", label: "NA-83" },
      { value: "NA-84", label: "NA-84" },
      { value: "NA-85", label: "NA-85" },
      { value: "NA-86", label: "NA-86" },
      { value: "NA-87", label: "NA-87" },
      { value: "NA-88", label: "NA-88" },
      { value: "NA-89", label: "NA-89" },
      { value: "NA-90", label: "NA-90" },
      { value: "NA-91", label: "NA-91" },
      { value: "NA-92", label: "NA-92" },
      { value: "NA-93", label: "NA-93" },
      { value: "NA-94", label: "NA-94" },
      { value: "NA-95", label: "NA-95" },
      { value: "NA-96", label: "NA-96" },
      { value: "NA-97", label: "NA-97" },
      { value: "NA-98", label: "NA-98" },
      { value: "NA-99", label: "NA-99" },
      { value: "NA-100", label: "NA-100" },
      { value: "LATAM-1", label: "LATAM-1" },
      { value: "LATAM-2", label: "LATAM-2" },
      { value: "LATAM-3", label: "LATAM-3" },
      { value: "LATAM-4", label: "LATAM-4" },
      { value: "LATAM-5", label: "LATAM-5" },
      { value: "LATAM-6", label: "LATAM-6" },
      { value: "LATAM-7", label: "LATAM-7" },
      { value: "LATAM-8", label: "LATAM-8" },
      { value: "LATAM-9", label: "LATAM-9" },
      { value: "LATAM-10", label: "LATAM-10" },
      { value: "LATAM-11", label: "LATAM-11" },
      { value: "LATAM-12", label: "LATAM-12" },
      { value: "LATAM-13", label: "LATAM-13" },
      { value: "LATAM-14", label: "LATAM-14" },
      { value: "LATAM-15", label: "LATAM-15" },
      { value: "LATAM-16", label: "LATAM-16" },
      { value: "LATAM-17", label: "LATAM-17" },
      { value: "LATAM-18", label: "LATAM-18" },
      { value: "LATAM-19", label: "LATAM-19" },
      { value: "LATAM-20", label: "LATAM-20" },
      { value: "LATAM-21", label: "LATAM-21" },
      { value: "LATAM-22", label: "LATAM-22" },
      { value: "LATAM-23", label: "LATAM-23" },
      { value: "LATAM-24", label: "LATAM-24" },
      { value: "LATAM-25", label: "LATAM-25" },
      { value: "LATAM-26", label: "LATAM-26" },
      { value: "LATAM-27", label: "LATAM-27" },
      { value: "LATAM-28", label: "LATAM-28" },
      { value: "LATAM-29", label: "LATAM-29" },
      { value: "LATAM-30", label: "LATAM-30" },
      { value: "LATAM-31", label: "LATAM-31" },
      { value: "LATAM-32", label: "LATAM-32" },
      { value: "LATAM-33", label: "LATAM-33" },
      { value: "LATAM-34", label: "LATAM-34" },
      { value: "LATAM-35", label: "LATAM-35" },
      { value: "LATAM-36", label: "LATAM-36" },
      { value: "LATAM-37", label: "LATAM-37" },
      { value: "LATAM-38", label: "LATAM-38" },
      { value: "LATAM-39", label: "LATAM-39" },
      { value: "LATAM-40", label: "LATAM-40" },
      { value: "LATAM-41", label: "LATAM-41" },
      { value: "LATAM-42", label: "LATAM-42" },
      { value: "LATAM-43", label: "LATAM-43" },
      { value: "LATAM-44", label: "LATAM-44" },
      { value: "LATAM-45", label: "LATAM-45" },
      { value: "LATAM-46", label: "LATAM-46" },
      { value: "LATAM-47", label: "LATAM-47" },
      { value: "LATAM-48", label: "LATAM-48" },
      { value: "LATAM-49", label: "LATAM-49" },
      { value: "LATAM-50", label: "LATAM-50" },
      { value: "LATAM-51", label: "LATAM-51" },
      { value: "LATAM-52", label: "LATAM-52" },
      { value: "LATAM-53", label: "LATAM-53" },
      { value: "LATAM-54", label: "LATAM-54" },
      { value: "LATAM-55", label: "LATAM-55" },
      { value: "LATAM-56", label: "LATAM-56" },
      { value: "LATAM-57", label: "LATAM-57" },
      { value: "LATAM-58", label: "LATAM-58" },
      { value: "LATAM-59", label: "LATAM-59" },
      { value: "LATAM-60", label: "LATAM-60" },
      { value: "LATAM-61", label: "LATAM-61" },
      { value: "LATAM-62", label: "LATAM-62" },
      { value: "LATAM-63", label: "LATAM-63" },
      { value: "LATAM-64", label: "LATAM-64" },
      { value: "LATAM-65", label: "LATAM-65" },
      { value: "LATAM-66", label: "LATAM-66" },
      { value: "LATAM-67", label: "LATAM-67" },
      { value: "LATAM-68", label: "LATAM-68" },
      { value: "LATAM-69", label: "LATAM-69" },
      { value: "LATAM-70", label: "LATAM-70" },
      { value: "LATAM-71", label: "LATAM-71" },
      { value: "LATAM-72", label: "LATAM-72" },
      { value: "LATAM-73", label: "LATAM-73" },
      { value: "LATAM-74", label: "LATAM-74" },
      { value: "LATAM-75", label: "LATAM-75" },
      { value: "LATAM-76", label: "LATAM-76" },
      { value: "LATAM-77", label: "LATAM-77" },
      { value: "LATAM-78", label: "LATAM-78" },
      { value: "LATAM-79", label: "LATAM-79" },
      { value: "LATAM-80", label: "LATAM-80" },
      { value: "LATAM-81", label: "LATAM-81" },
      { value: "LATAM-82", label: "LATAM-82" },
      { value: "LATAM-83", label: "LATAM-83" },
      { value: "LATAM-84", label: "LATAM-84" },
      { value: "LATAM-85", label: "LATAM-85" },
      { value: "LATAM-86", label: "LATAM-86" },
      { value: "LATAM-87", label: "LATAM-87" },
      { value: "LATAM-88", label: "LATAM-88" },
      { value: "LATAM-89", label: "LATAM-89" },
      { value: "LATAM-90", label: "LATAM-90" },
      { value: "LATAM-91", label: "LATAM-91" },
      { value: "LATAM-92", label: "LATAM-92" },
      { value: "LATAM-93", label: "LATAM-93" },
      { value: "LATAM-94", label: "LATAM-94" },
      { value: "LATAM-95", label: "LATAM-95" },
      { value: "LATAM-96", label: "LATAM-96" },
      { value: "LATAM-97", label: "LATAM-97" },
      { value: "LATAM-98", label: "LATAM-98" },
      { value: "LATAM-99", label: "LATAM-99" },
      { value: "LATAM-100", label: "LATAM-100" },
      { value: "EU-1", label: "EU-1" },
      { value: "EU-2", label: "EU-2" },
      { value: "EU-3", label: "EU-3" },
      { value: "EU-4", label: "EU-4" },
      { value: "EU-5", label: "EU-5" },
      { value: "EU-6", label: "EU-6" },
      { value: "EU-7", label: "EU-7" },
      { value: "EU-8", label: "EU-8" },
      { value: "EU-9", label: "EU-9" },
      { value: "EU-10", label: "EU-10" },
      { value: "EU-11", label: "EU-11" },
      { value: "EU-12", label: "EU-12" },
      { value: "EU-13", label: "EU-13" },
      { value: "EU-14", label: "EU-14" },
      { value: "EU-15", label: "EU-15" },
      { value: "EU-16", label: "EU-16" },
      { value: "EU-17", label: "EU-17" },
      { value: "EU-18", label: "EU-18" },
      { value: "EU-19", label: "EU-19" },
      { value: "EU-20", label: "EU-20" },
      { value: "EU-21", label: "EU-21" },
      { value: "EU-22", label: "EU-22" },
      { value: "EU-23", label: "EU-23" },
      { value: "EU-24", label: "EU-24" },
      { value: "EU-25", label: "EU-25" },
      { value: "EU-26", label: "EU-26" },
      { value: "EU-27", label: "EU-27" },
      { value: "EU-28", label: "EU-28" },
      { value: "EU-29", label: "EU-29" },
      { value: "EU-30", label: "EU-30" },
      { value: "EU-31", label: "EU-31" },
      { value: "EU-32", label: "EU-32" },
      { value: "EU-33", label: "EU-33" },
      { value: "EU-34", label: "EU-34" },
      { value: "EU-35", label: "EU-35" },
      { value: "EU-36", label: "EU-36" },
      { value: "EU-37", label: "EU-37" },
      { value: "EU-38", label: "EU-38" },
      { value: "EU-39", label: "EU-39" },
      { value: "EU-40", label: "EU-40" },
      { value: "EU-41", label: "EU-41" },
      { value: "EU-42", label: "EU-42" },
      { value: "EU-43", label: "EU-43" },
      { value: "EU-44", label: "EU-44" },
      { value: "EU-45", label: "EU-45" },
      { value: "EU-46", label: "EU-46" },
      { value: "EU-47", label: "EU-47" },
      { value: "EU-48", label: "EU-48" },
      { value: "EU-49", label: "EU-49" },
      { value: "EU-50", label: "EU-50" },
      { value: "EU-51", label: "EU-51" },
      { value: "EU-52", label: "EU-52" },
      { value: "EU-53", label: "EU-53" },
      { value: "EU-54", label: "EU-54" },
      { value: "EU-55", label: "EU-55" },
      { value: "EU-56", label: "EU-56" },
      { value: "EU-57", label: "EU-57" },
      { value: "EU-58", label: "EU-58" },
      { value: "EU-59", label: "EU-59" },
      { value: "EU-60", label: "EU-60" },
      { value: "EU-61", label: "EU-61" },
      { value: "EU-62", label: "EU-62" },
      { value: "EU-63", label: "EU-63" },
      { value: "EU-64", label: "EU-64" },
      { value: "EU-65", label: "EU-65" },
      { value: "EU-66", label: "EU-66" },
      { value: "EU-67", label: "EU-67" },
      { value: "EU-68", label: "EU-68" },
      { value: "EU-69", label: "EU-69" },
      { value: "EU-70", label: "EU-70" },
      { value: "EU-71", label: "EU-71" },
      { value: "EU-72", label: "EU-72" },
      { value: "EU-73", label: "EU-73" },
      { value: "EU-74", label: "EU-74" },
      { value: "EU-75", label: "EU-75" },
      { value: "EU-76", label: "EU-76" },
      { value: "EU-77", label: "EU-77" },
      { value: "EU-78", label: "EU-78" },
      { value: "EU-79", label: "EU-79" },
      { value: "EU-80", label: "EU-80" },
      { value: "EU-81", label: "EU-81" },
      { value: "EU-82", label: "EU-82" },
      { value: "EU-83", label: "EU-83" },
      { value: "EU-84", label: "EU-84" },
      { value: "EU-85", label: "EU-85" },
      { value: "EU-86", label: "EU-86" },
      { value: "EU-87", label: "EU-87" },
      { value: "EU-88", label: "EU-88" },
      { value: "EU-89", label: "EU-89" },
      { value: "EU-90", label: "EU-90" },
      { value: "EU-91", label: "EU-91" },
      { value: "EU-92", label: "EU-92" },
      { value: "EU-93", label: "EU-93" },
      { value: "EU-94", label: "EU-94" },
      { value: "EU-95", label: "EU-95" },
      { value: "EU-96", label: "EU-96" },
      { value: "EU-97", label: "EU-97" },
      { value: "EU-98", label: "EU-98" },
      { value: "EU-99", label: "EU-99" },
      { value: "EU-100", label: "EU-100" },
      { value: "EE-1", label: "EE-1" },
      { value: "EE-2", label: "EE-2" },
      { value: "EE-3", label: "EE-3" },
      { value: "EE-4", label: "EE-4" },
      { value: "EE-5", label: "EE-5" },
      { value: "EE-6", label: "EE-6" },
      { value: "EE-7", label: "EE-7" },
      { value: "EE-8", label: "EE-8" },
      { value: "EE-9", label: "EE-9" },
      { value: "EE-10", label: "EE-10" },
      { value: "EE-11", label: "EE-11" },
      { value: "EE-12", label: "EE-12" },
      { value: "EE-13", label: "EE-13" },
      { value: "EE-14", label: "EE-14" },
      { value: "EE-15", label: "EE-15" },
      { value: "EE-16", label: "EE-16" },
      { value: "EE-17", label: "EE-17" },
      { value: "EE-18", label: "EE-18" },
      { value: "EE-19", label: "EE-19" },
      { value: "EE-20", label: "EE-20" },
      { value: "EE-21", label: "EE-21" },
      { value: "EE-22", label: "EE-22" },
      { value: "EE-23", label: "EE-23" },
      { value: "EE-24", label: "EE-24" },
      { value: "EE-25", label: "EE-25" },
      { value: "EE-26", label: "EE-26" },
      { value: "EE-27", label: "EE-27" },
      { value: "EE-28", label: "EE-28" },
      { value: "EE-29", label: "EE-29" },
      { value: "EE-30", label: "EE-30" },
      { value: "EE-31", label: "EE-31" },
      { value: "EE-32", label: "EE-32" },
      { value: "EE-33", label: "EE-33" },
      { value: "EE-34", label: "EE-34" },
      { value: "EE-35", label: "EE-35" },
      { value: "EE-36", label: "EE-36" },
      { value: "EE-37", label: "EE-37" },
      { value: "EE-38", label: "EE-38" },
      { value: "EE-39", label: "EE-39" },
      { value: "EE-40", label: "EE-40" },
      { value: "EE-41", label: "EE-41" },
      { value: "EE-42", label: "EE-42" },
      { value: "EE-43", label: "EE-43" },
      { value: "EE-44", label: "EE-44" },
      { value: "EE-45", label: "EE-45" },
      { value: "EE-46", label: "EE-46" },
      { value: "EE-47", label: "EE-47" },
      { value: "EE-48", label: "EE-48" },
      { value: "EE-49", label: "EE-49" },
      { value: "EE-50", label: "EE-50" },
      { value: "EE-51", label: "EE-51" },
      { value: "EE-52", label: "EE-52" },
      { value: "EE-53", label: "EE-53" },
      { value: "EE-54", label: "EE-54" },
      { value: "EE-55", label: "EE-55" },
      { value: "EE-56", label: "EE-56" },
      { value: "EE-57", label: "EE-57" },
      { value: "EE-58", label: "EE-58" },
      { value: "EE-59", label: "EE-59" },
      { value: "EE-60", label: "EE-60" },
      { value: "EE-61", label: "EE-61" },
      { value: "EE-62", label: "EE-62" },
      { value: "EE-63", label: "EE-63" },
      { value: "EE-64", label: "EE-64" },
      { value: "EE-65", label: "EE-65" },
      { value: "EE-66", label: "EE-66" },
      { value: "EE-67", label: "EE-67" },
      { value: "EE-68", label: "EE-68" },
      { value: "EE-69", label: "EE-69" },
      { value: "EE-70", label: "EE-70" },
      { value: "EE-71", label: "EE-71" },
      { value: "EE-72", label: "EE-72" },
      { value: "EE-73", label: "EE-73" },
      { value: "EE-74", label: "EE-74" },
      { value: "EE-75", label: "EE-75" },
      { value: "EE-76", label: "EE-76" },
      { value: "EE-77", label: "EE-77" },
      { value: "EE-78", label: "EE-78" },
      { value: "EE-79", label: "EE-79" },
      { value: "EE-80", label: "EE-80" },
      { value: "EE-81", label: "EE-81" },
      { value: "EE-82", label: "EE-82" },
      { value: "EE-83", label: "EE-83" },
      { value: "EE-84", label: "EE-84" },
      { value: "EE-85", label: "EE-85" },
      { value: "EE-86", label: "EE-86" },
      { value: "EE-87", label: "EE-87" },
      { value: "EE-88", label: "EE-88" },
      { value: "EE-89", label: "EE-89" },
      { value: "EE-90", label: "EE-90" },
      { value: "EE-91", label: "EE-91" },
      { value: "EE-92", label: "EE-92" },
      { value: "EE-93", label: "EE-93" },
      { value: "EE-94", label: "EE-94" },
      { value: "EE-95", label: "EE-95" },
      { value: "EE-96", label: "EE-96" },
      { value: "EE-97", label: "EE-97" },
      { value: "EE-98", label: "EE-98" },
      { value: "EE-99", label: "EE-99" }
    ],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Tarisland kamu",
    ],
  },

  "Tower Of Fantasy (SEA)": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
      { value: "Asia Pacific-Eden", label: "Asia Pacific-Eden" },
      { value: "Asia Pacific-Fate", label: "Asia Pacific-Fate" },
      { value: "Asia Pacific-Nova", label: "Asia Pacific-Nova" },
      { value: "Asia Pacific-Ruby", label: "Asia Pacific-Ruby" },
      { value: "Asia Pacific-Babel", label: "Asia Pacific-Babel" },
      { value: "Asia Pacific-Gomap", label: "Asia Pacific-Gomap" },
      { value: "Asia Pacific-Pluto", label: "Asia Pacific-Pluto" },
      { value: "Asia Pacific-Sushi", label: "Asia Pacific-Sushi" },
      { value: "Asia Pacific-Venus", label: "Asia Pacific-Venus" },
      { value: "Asia Pacific-Galaxy", label: "Asia Pacific-Galaxy" },
      { value: "Asia Pacific-Memory", label: "Asia Pacific-Memory" },
      { value: "Asia Pacific-Oxygen", label: "Asia Pacific-Oxygen" },
      { value: "Asia Pacific-Sakura", label: "Asia Pacific-Sakura" },
      { value: "Asia Pacific-Seeker", label: "Asia Pacific-Seeker" },
      { value: "Asia Pacific-Shinya", label: "Asia Pacific-Shinya" },
      { value: "Asia Pacific-Stella", label: "Asia Pacific-Stella" },
      { value: "Asia Pacific-Uranus", label: "Asia Pacific-Uranus" },
      { value: "Asia Pacific-Utopia", label: "Asia Pacific-Utopia" },
      { value: "Asia Pacific-Jupiter", label: "Asia Pacific-Jupiter" },
      { value: "Asia Pacific-Sweetie", label: "Asia Pacific-Sweetie" },
      { value: "Asia Pacific-Atlantis", label: "Asia Pacific-Atlantis" },
      { value: "Asia Pacific-Daybreak", label: "Asia Pacific-Daybreak" },
      { value: "Asia Pacific-Takoyaki", label: "Asia Pacific-Takoyaki" },
      { value: "Asia Pacific-Adventure", label: "Asia Pacific-Adventure" },
      { value: "Asia Pacific-Yggdrasil", label: "Asia Pacific-Yggdrasil" },
      { value: "Asia Pacific-Cocoaiteruyo", label: "Asia Pacific-Cocoaiteruyo" },
      { value: "Asia Pacific-Food fighter", label: "Asia Pacific-Food fighter" },
      { value: "Asia Pacific-Mars", label: "Asia Pacific-Mars" },
      { value: "Asia Pacific-Vega", label: "Asia Pacific-Vega" },
      { value: "Asia Pacific-Neptune", label: "Asia Pacific-Neptune" },
      { value: "Asia Pacific-Tenpura", label: "Asia Pacific-Tenpura" },
      { value: "Asia Pacific-Moon", label: "Asia Pacific-Moon" },
      { value: "Asia Pacific-Mihashira", label: "Asia Pacific-Mihashira" },
      { value: "Asia Pacific-Cocokonderu", label: "Asia Pacific-Cocokonderu" },
      { value: "Europe-Aimanium", label: "Europe-Aimanium" },
      { value: "Europe-Alintheus", label: "Europe-Alintheus" },
      { value: "Europe-Andoes", label: "Europe-Andoes" },
      { value: "Europe-Anomora", label: "Europe-Anomora" },
      { value: "Europe-Astora", label: "Europe-Astora" },
      { value: "Europe-Valstamm", label: "Europe-Valstamm" },
      { value: "Europe-Blumous", label: "Europe-Blumous" },
      { value: "Europe-Celestialrise", label: "Europe-Celestialrise" },
      { value: "Europe-Cosmos", label: "Europe-Cosmos" },
      { value: "Europe-Dyrnwyn", label: "Europe-Dyrnwyn" },
      { value: "Europe-Elypium", label: "Europe-Elypium" },
      { value: "Europe-Excalibur", label: "Europe-Excalibur" },
      { value: "Europe-Espoir IV", label: "Europe-Espoir IV" },
      { value: "Europe-Estrela", label: "Europe-Estrela" },
      { value: "Europe-Ether", label: "Europe-Ether" },
      { value: "Europe-Ex Nihilor", label: "Europe-Ex Nihilor" },
      { value: "Europe-Futuria", label: "Europe-Futuria" },
      { value: "Europe-Hephaestus", label: "Europe-Hephaestus" },
      { value: "Europe-Midgard", label: "Europe-Midgard" },
      { value: "Europe-Iter", label: "Europe-Iter" },
      { value: "Europe-Kuura", label: "Europe-Kuura" },
      { value: "Europe-Lycoris", label: "Europe-Lycoris" },
      { value: "Europe-Lyramiel", label: "Europe-Lyramiel" },
      { value: "Europe-Magenta", label: "Europe-Magenta" },
      { value: "Europe-Magia Przygoda Aida", label: "Europe-Magia Przygoda Aida" },
      { value: "Europe-Olivine", label: "Europe-Olivine" },
      { value: "Europe-Omnium Prime", label: "Europe-Omnium Prime" },
      { value: "Europe-Turmus", label: "Europe-Turmus" },
      { value: "Europe-Transport Hub", label: "Europe-Transport Hub" },
      { value: "Europe-The Lumina", label: "Europe-The Lumina" },
      { value: "Europe-Seaforth Dock", label: "Europe-Seaforth Dock" },
      { value: "Europe-Silvercoast Lab", label: "Europe-Silvercoast Lab" },
      { value: "Europe-Karst Cave", label: "Europe-Karst Cave" },
      { value: "North America-The Glades", label: "North America-The Glades" },
      { value: "North America-Nightfall", label: "North America-Nightfall" },
      { value: "North America-Frontier", label: "North America-Frontier" },
      { value: "North America-Libera", label: "North America-Libera" },
      { value: "North America-Solaris", label: "North America-Solaris" },
      { value: "North America-Freedom-Oasis", label: "North America-Freedom-Oasis" },
      { value: "North America-Oasis", label: "North America-Oasis" },
      { value: "North America-The worlds between", label: "North America-The worlds between" },
      { value: "North America-Radiant", label: "North America-Radiant" },
      { value: "North America-Tempest", label: "North America-Tempest" },
      { value: "North America-New Era", label: "North America-New Era" },
      { value: "North America-Observer", label: "North America-Observer" },
      { value: "North America-Lunalite", label: "North America-Lunalite" },
      { value: "North America-Starlight", label: "North America-Starlight" },
      { value: "North America-Myriad", label: "North America-Myriad" },
      { value: "North America-Lighthouse", label: "North America-Lighthouse" },
      { value: "North America-Oumuamua", label: "North America-Oumuamua" },
      { value: "North America-Eternium Phantasy", label: "North America-Eternium Phantasy" },
      { value: "North America-Sol-III", label: "North America-Sol-III" },
      { value: "North America-Silver Bridge", label: "North America-Silver Bridge" },
      { value: "North America-Azure Plane", label: "North America-Azure Plane" },
      { value: "North America-Nirvana", label: "North America-Nirvana" },
      { value: "North America-Ozera", label: "North America-Ozera" },
      { value: "South America-Orion", label: "South America-Orion" },
      { value: "South America-Luna Azul", label: "South America-Luna Azul" },
      { value: "South America-Tiamat", label: "South America-Tiamat" },
      { value: "South America-Hope", label: "South America-Hope" },
      { value: "South America-Tanzanite", label: "South America-Tanzanite" },
      { value: "South America-Calodesma Seven", label: "South America-Calodesma Seven" },
      { value: "South America-Antlia", label: "South America-Antlia" },
      { value: "South America-Pegasus", label: "South America-Pegasus" },
      { value: "South America-Phoenix", label: "South America-Phoenix" },
      { value: "South America-Centaurus", label: "South America-Centaurus" },
      { value: "South America-Cepheu", label: "South America-Cepheu" },
      { value: "South America-Columba", label: "South America-Columba" },
      { value: "South America-Corvus", label: "South America-Corvus" },
      { value: "South America-Cygnus", label: "South America-Cygnus" },
      { value: "South America-Grus", label: "South America-Grus" },
      { value: "South America-Hydra", label: "South America-Hydra" },
      { value: "South America-Lyra", label: "South America-Lyra" },
      { value: "South America-Ophiuchus", label: "South America-Ophiuchus" },
      { value: "Southeast Asia-Phantasia", label: "Southeast Asia-Phantasia" },
      { value: "Southeast Asia-Mechafield", label: "Southeast Asia-Mechafield" },
      { value: "Southeast Asia-Ethereal Dream", label: "Southeast Asia-Ethereal Dream" },
      { value: "Southeast Asia-Odyssey", label: "Southeast Asia-Odyssey" },
      { value: "Southeast Asia-Aestral-Noa", label: "Southeast Asia-Aestral-Noa" },
      { value: "Southeast Asia-Osillron", label: "Southeast Asia-Osillron" },
      { value: "Southeast Asia-Chandra", label: "Southeast Asia-Chandra" },
      { value: "Southeast Asia-Saeri", label: "Southeast Asia-Saeri" },
      { value: "Southeast Asia-Aeria", label: "Southeast Asia-Aeria" },
      { value: "Southeast Asia-Scarlet", label: "Southeast Asia-Scarlet" },
      { value: "Southeast Asia-Gumi Gumi", label: "Southeast Asia-Gumi Gumi" },
      { value: "Southeast Asia-Fantasia", label: "Southeast Asia-Fantasia" },
      { value: "Southeast Asia-Oryza", label: "Southeast Asia-Oryza" },
      { value: "Southeast Asia-Stardust", label: "Southeast Asia-Stardust" },
      { value: "Southeast Asia-Arcania", label: "Southeast Asia-Arcania" },
      { value: "Southeast Asia-Animus", label: "Southeast Asia-Animus" },
      { value: "Southeast Asia-Mistilteinn", label: "Southeast Asia-Mistilteinn" },
      { value: "Southeast Asia-Valhalla", label: "Southeast Asia-Valhalla" },
      { value: "Southeast Asia-Illyrian", label: "Southeast Asia-Illyrian" },
      { value: "Southeast Asia-Florione", label: "Southeast Asia-Florione" },
      { value: "Southeast Asia-Oneiros", label: "Southeast Asia-Oneiros" },
      { value: "Southeast Asia-Famtosyana", label: "Southeast Asia-Famtosyana" },
      { value: "Southeast Asia-Edenia", label: "Southeast Asia-Edenia" }
    ],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Tower of Fantasy (SEA) kamu",
    ],
  },

  Valorant: {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Riot ID + Tagline", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Points yang kamu inginkan",
      "Masukkan Riot ID Anda, Contoh : Nerogaming#1234",
      "Selesaikan pembayaran",
      "Points akan ditambahkan ke akun Valorant kamu",
      "Pastikan untuk top up dari pukul 08:00 WIB - 23:00 WIB. Memesan diluar jam proses merupakan tanggung jawab pembeli dan transaksi tidak dapat direfund",
    ],
  },

  "Whiteout Survival": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Whiteout Survival kamu",
    ],
  },

  Zepeto: {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("Nickname Zepeto is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Nickname Zepeto", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Zepeto ! kamu",
    ],
  },
  "Super SUS": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("ID Space is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "ID Space", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Super SUS kamu",
    ],
  },
  Undawn: {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pastikan kamu login ingame di Garena",
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Zepeto kamu",
    ],
  },
  "EA SPORTS FC Mobile": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Zepeto kamu",
    ],
  },
  "Zenless Zone Zero": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
          { value: "os_usa", label: "America" },
          { value: "os_euro", label: "Europe" },
          { value: "os_asia", label: "Asia" },
          { value: "os_cht", label: "TW, HK, MO" },
        ],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Zepeto kamu",
    ],
  },
  "Ragnarok M - Eternal Love": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
      { value: "eternal love", label: "Eternal Love" },
      { value: "midnight party", label: "Midnight Party" },
      { value: "memory of faith", label: "Memory of Faith" },
      { value: "valhalla glory", label: "Valhalla Glory" },
      { value: "port city", label: "Port City" }
    ],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID dan pilih Server ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Ragnarok M - Eternal Love kamu",
    ],
  },
  "Alchemy Stars": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
      { value: "sea server", label: "SEA Server" },
      { value: "global server", label: "Global Server" }
    ],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Zepeto kamu",
    ],
  },
  "Goddess of Victory: Nikke": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
      { value: "sea", label: "SEA" },
      { value: "global", label: "Global" },
      { value: "na", label: "NA" },
      { value: "jp", label: "JP" },
      { value: "kr", label: "KR" }
    ],
      },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Zepeto kamu",
    ],
  },
  "Call of Duty Mobile (Bind Garena)": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Zepeto kamu",
    ],
  },
  "Legends of Runeterra": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("RIOT ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "RIOT ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Points yang kamu inginkan",
      "Masukkan RIOT ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Legends of Runeterra kamu",
    ],
  },
  Lokapala: {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Citrine yang kamu inginkan",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Lokapala kamu",
    ],
  },
  "Mirage:Perfect Skyline": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
          { value: "1", label: "Mirage" },
          { value: "2", label: "Skyreach" },
          { value: "3", label: "Aelley" },
          { value: "4", label: "Deepapple" },
          { value: "5", label: "Fairbel1" },
          { value: "6", label: "Icebank" },
          { value: "7", label: "Mageburn" },
          { value: "8", label: "Seacliff" },
          { value: "9", label: "Valbarrow" },
          { value: "10", label: "Watermont" },
          { value: "11", label: "Aelcliff" },
          { value: "12", label: "Fairburn" },
          { value: "13", label: "Glassbell" },
          { value: "14", label: "Icebell" },
          { value: "15", label: "Janbush" },
          { value: "16", label: "Oaktown" },
          { value: "17", label: "Vallea" },
          { value: "18", label: "Deepbush" },
          { value: "19", label: "Eastcoast" },
          { value: "20", label: "Fairhill" },
          { value: "21", label: "Janhall" },
          { value: "22", label: "Lakeport" },
          { value: "23", label: "Magemarsh" },
          { value: "24", label: "Newcliff" },
          { value: "25", label: "Oldash" },
          { value: "26", label: "Pinepond" },
          { value: "27", label: "Seahollow" },
          { value: "28", label: "Valport" },
          { value: "29", label: "Aelston" },
          { value: "30", label: "Eastdeer" },
          { value: "31", label: "Glassden" },
          { value: "32", label: "Icebush" },
          { value: "33", label: "Janlake" },
          { value: "34", label: "Oldbutter" },
          { value: "35", label: "Pinetown" },
          { value: "36", label: "Raykeep" },
          { value: "37", label: "Valville" },
          { value: "38", label: "Aldapple" },
          { value: "39", label: "Baykeep" },
          { value: "40", label: "Clearden" },
          { value: "41", label: "Deepcliff" },
          { value: "42", label: "Easthall" },
          { value: "43", label: "Fairmarsh" },
          { value: "44", label: "Icecastle" },
          { value: "45", label: "Janmeadow" },
          { value: "46", label: "Pinevale" },
          { value: "47", label: "Vertbank" },
          { value: "48", label: "Wayford" },
          { value: "49", label: "Aldbeach" },
          { value: "50", label: "Baypond" },
          { value: "51", label: "Deepcoast" },
          { value: "52", label: "Glassedge" },
          { value: "53", label: "Highacre" },
          { value: "54", label: "Icecoast" },
          { value: "55", label: "Landcliff" },
          { value: "56", label: "Newfield" },
          { value: "57", label: "Oldgate" },
          { value: "58", label: "Pinewall" },
          { value: "59", label: "Shadehedge" },
          { value: "60", label: "Vertbrook" },
          { value: "61", label: "Wayhill" },
          { value: "62", label: "Deepdell" },
          { value: "63", label: "Eastmarsh" },
          { value: "64", label: "Glassford" },
          { value: "65", label: "Highash" },
          { value: "66", label: "Icefall" },
          { value: "67", label: "Jantown" },
          { value: "68", label: "Landmoor" },
          { value: "69", label: "Newhaven" },
          { value: "70", label: "Oldham" },
          { value: "71", label: "Pondcastle" },
          { value: "72", label: "Raymead" },
          { value: "73", label: "Shadelake" },
          { value: "74", label: "Vertbutter" },
          { value: "75", label: "Wayholt" },
          { value: "76", label: "Beachgate" },
          { value: "77", label: "Deepholt" },
          { value: "78", label: "Eastmill" },
          { value: "79", label: "Fairshadow" },
          { value: "80", label: "Glasslea" },
          { value: "81", label: "Highcrest" },
          { value: "82", label: "Icemage" },
          { value: "83", label: "Newhedge" },
          { value: "84", label: "Oldmeadow" },
          { value: "85", label: "Pondcoast" },
          { value: "86", label: "Raymill" },
          { value: "87", label: "Shadepond" },
          { value: "88", label: "Vertcastle" },
          { value: "89", label: "Waynesse" },
          { value: "90", label: "Beachholt" },
          { value: "91", label: "Clearfog" },
          { value: "92", label: "Deephurst" },
          { value: "93", label: "Eastsage" },
          { value: "94", label: "Fairvale" },
          { value: "95", label: "Highland" },
          { value: "96", label: "Mallowdeer" },
          { value: "97", label: "Newholt" },
          { value: "98", label: "Oldpond" },
          { value: "99", label: "Pondlake" },
          { value: "100", label: "Vertdale" },
          { value: "101", label: "Aldlake" },
          { value: "102", label: "Beechhedge" },
          { value: "103", label: "Clearhill" },
          { value: "104", label: "Deepmarble" },
          { value: "105", label: "Eastston" },
          { value: "106", label: "Highley" },
          { value: "107", label: "Astria" },
          { value: "108", label: "Tempestus" },
          { value: "109", label: "Puddles" },
          { value: "110", label: "Anemis" },
          { value: "111", label: "Bane" },
          { value: "112", label: "Snift" },
          { value: "113", label: "Storm" },
          { value: "114", label: "Granius" },
          { value: "115", label: "Iglis" },
          { value: "116", label: "Optix" },
          { value: "117", label: "Vexus" },
          { value: "118", label: "Aros" },
          { value: "119", label: "Gale" },
          { value: "120", label: "Jinx" },
          { value: "121", label: "Spiritus" },
          { value: "122", label: "Clipse" },
          { value: "123", label: "Enigma" },
          { value: "124", label: "Animax" },
          { value: "125", label: "Inanos" },
          { value: "126", label: "Lucernas" },
          { value: "127", label: "Iniq" },
          { value: "128", label: "Vape" },
          { value: "129", label: "Aquara" },
          { value: "130", label: "Parados" },
          { value: "131", label: "Intra" },
          { value: "132", label: "Slate" },
          { value: "133", label: "Mistral" },
          { value: "134", label: "Clot" },
          { value: "135", label: "Aeos" },
          { value: "136", label: "Decessus" },
          { value: "137", label: "Scorch" },
          { value: "138", label: "Hemall" },
          { value: "139", label: "Fuego" },
          { value: "140", label: "Mojo" },
          { value: "141", label: "Allure" },
          { value: "142", label: "Theuros" },
          { value: "143", label: "Lumen" },
          { value: "144", label: "Tsuna" },
          { value: "145", label: "Terrane" },
          { value: "146", label: "Deus" },
          { value: "147", label: "Cruor" },
          { value: "148", label: "Bolt" },
          { value: "149", label: "Incedis" },
          { value: "150", label: "Synapse" },
          { value: "151", label: "Aquis" },
          { value: "152", label: "Glacis" },
          { value: "153", label: "Aetas" },
          { value: "154", label: "Sprite" },
          { value: "155", label: "Flair" },
          { value: "156", label: "Plasma" },
          { value: "157", label: "Genesis" },
          { value: "158", label: "Entros" },
          { value: "159", label: "Infernus" },
          { value: "160", label: "Ocus" },
          { value: "161", label: "Magnos" },
          { value: "162", label: "Arter" },
          { value: "163", label: "Sanguin" },
          { value: "164", label: "Nighe" },
          { value: "165", label: "Mortos" },
          { value: "166", label: "Sod" },
          { value: "167", label: "Vitae" },
          { value: "168", label: "Blossom" },
          { value: "169", label: "Firn" },
          { value: "170", label: "Mementos" },
          { value: "171", label: "SignalZen" },
          { value: "172", label: "Alpha" },
          { value: "173", label: "Saint" },
          { value: "174", label: "Juju" },
          { value: "175", label: "Lambence" },
          { value: "176", label: "Smoldris" },
          { value: "177", label: "Lumis" },
          { value: "178", label: "Tesla" },
          { value: "179", label: "Synergy" },
          { value: "180", label: "Corax" },
          { value: "181", label: "Tsunis" },
          { value: "182", label: "Malefis" },
          { value: "183", label: "Harmony" },
          { value: "184", label: "Conjis" },
          { value: "185", label: "Desa" },
          { value: "186", label: "Aurora" },
          { value: "187", label: "Echo" },
          { value: "188", label: "Holmes" },
          { value: "189", label: "Zeus" },
          { value: "190", label: "Chemos" },
          { value: "191", label: "Central" },
          { value: "192", label: "Neo" },
          { value: "193", label: "Codex" },
          { value: "194", label: "Throm" },
          { value: "195", label: "Lumos" },
          { value: "196", label: "Cerebus" },
          { value: "197", label: "Sanguinus" },
          { value: "198", label: "Anomalis" },
          { value: "199", label: "Halos" },
          { value: "200", label: "Ceauno" },
          { value: "201", label: "Spock" },
          { value: "202", label: "Salis" },
          { value: "203", label: "Umber" },
          { value: "204", label: "Heaf" },
          { value: "205", label: "Tatheam" },
          { value: "206", label: "Liquis" },
          { value: "207", label: "Lustrous" },
          { value: "208", label: "Avian" },
          { value: "209", label: "Arcanis" },
          { value: "210", label: "Anemia" },
          { value: "211", label: "Inception" },
          { value: "212", label: "Cyclonius" },
          { value: "213", label: "Ragnis" },
          { value: "214", label: "Vitalus" },
          { value: "215", label: "Azuris" },
          { value: "216", label: "Arte" },
          { value: "217", label: "Solas" },
          { value: "218", label: "Lussios" },
          { value: "219", label: "Ashe" },
          { value: "220", label: "Galvan" },
          { value: "221", label: "Heinios" },
          { value: "222", label: "Prism" },
          { value: "223", label: "Voxis" },
          { value: "224", label: "Tec" },
          { value: "225", label: "Lucus" },
          { value: "226", label: "Glum" },
          { value: "227", label: "Omni" },
          { value: "228", label: "Cryo" },
          { value: "229", label: "Omega" },
          { value: "230", label: "Incantus" },
          { value: "231", label: "Soots" },
          { value: "232", label: "Vox" },
          { value: "233", label: "Glamos" },
          { value: "234", label: "Peros" },
          { value: "235", label: "Hema" },
          { value: "236", label: "Imperos" },
          { value: "237", label: "Hydrius" },
          { value: "238", label: "Precipise" },
          { value: "239", label: "Sanguine" },
          { value: "240", label: "Soros" },
          { value: "241", label: "Ramman" },
          { value: "242", label: "Genos" },
          { value: "243", label: "Oracle" },
          { value: "244", label: "Quary" },
          { value: "245", label: "Avalan" },
          { value: "246", label: "Carer" },
          { value: "247", label: "Aspect" },
          { value: "248", label: "Huricis" },
          { value: "249", label: "Clarity" },
          { value: "250", label: "Cryogen" },
          { value: "251", label: "Aeranas" },
          { value: "252", label: "Fluke" },
          { value: "253", label: "Stratos" },
          { value: "254", label: "Thinkerer" },
          { value: "255", label: "Switch" },
          { value: "256", label: "Twileigh" },
          { value: "257", label: "Flarion" },
          { value: "258", label: "Thromb" },
          { value: "259", label: "Elec" },
          { value: "260", label: "Saline" },
          { value: "261", label: "Sange" },
          { value: "262", label: "Yasha" },
          { value: "263", label: "Volance" },
          { value: "264", label: "Myrios" },
          { value: "265", label: "Acidosis" },
          { value: "266", label: "Precipe" },
          { value: "267", label: "Fusilis" },
          { value: "268", label: "Kindle" },
          { value: "269", label: "Golem" },
          { value: "270", label: "Flo" },
          { value: "271", label: "Flux" },
          { value: "272", label: "Limus" },
          { value: "273", label: "Granit" },
          { value: "274", label: "Zero" },
          { value: "275", label: "Pyroc" },
          { value: "276", label: "Fenix" },
          { value: "277", label: "Septos" },
          { value: "278", label: "Fulg" },
          { value: "279", label: "Vessel" },
          { value: "280", label: "Ware" },
          { value: "281", label: "Sonas" },
          { value: "282", label: "Hurican" },
          { value: "283", label: "Magmis" },
          { value: "284", label: "Butler" },
          { value: "285", label: "Azura" },
          { value: "286", label: "Colossus" },
          { value: "287", label: "Thera" },
          { value: "288", label: "Exanimus" },
          { value: "289", label: "Disas" },
          { value: "290", label: "Adum" },
          { value: "291", label: "Skye" },
          { value: "292", label: "Cerulis" },
          { value: "293", label: "Oria" },
          { value: "294", label: "Alchos" },
          { value: "295", label: "Lutu" },
          { value: "296", label: "Flaik" },
          { value: "297", label: "Whiff" },
          { value: "298", label: "Icefield" },
          { value: "299", label: "Ancient" },
          { value: "300", label: "Shadow" },
          { value: "301", label: "Atlantis" },
          { value: "302", label: "Ence" },
          { value: "303", label: "Fairy" },
          { value: "304", label: "Minzie" },
          { value: "305", label: "Swort" },
          { value: "306", label: "Woodland" },
          { value: "307", label: "Bringer" },
          { value: "308", label: "Xavier" },
          { value: "309", label: "Star" },
          { value: "310", label: "Platinum" },
          { value: "311", label: "Sherwin" },
          { value: "312", label: "Sayee" },
          { value: "313", label: "Abby" },
          { value: "314", label: "Rayne" },
          { value: "315", label: "Falconer" },
          { value: "316", label: "Snowy" },
          { value: "317", label: "Delta" },
          { value: "318", label: "Adherent" },
          { value: "319", label: "Mash" },
          { value: "320", label: "Oberon" },
          { value: "321", label: "Merlin" },
          { value: "322", label: "Silence" },
          { value: "323", label: "One" },
          { value: "324", label: "Unkilling" },
          { value: "325", label: "Siegfried" },
          { value: "326", label: "Naro" },
          { value: "327", label: "Sigurd" },
          { value: "300000", label: "Skyline" },
          { value: "300001", label: "Petalavia" },
          { value: "300002", label: "Blackford" },
          { value: "300003", label: "Eriston" },
          { value: "300004", label: "Grasswall" },
          { value: "300005", label: "Norwall" },
          { value: "300006", label: "Violetwyn" },
          { value: "300007", label: "Eriwall" },
          { value: "300008", label: "Fayshade" },
          { value: "300009", label: "Greenelf" },
          { value: "300010", label: "Lochcourt" },
          { value: "300011", label: "Blackoak" },
          { value: "300012", label: "Cordale" },
          { value: "300013", label: "Eriwick" },
          { value: "300014", label: "Fayshore" },
          { value: "300015", label: "Roseford" },
          { value: "300016", label: "Corhollow" },
          { value: "300017", label: "Lochgate" },
          { value: "300018", label: "Marblemage" },
          { value: "300019", label: "Rosemarsh" },
          { value: "300020", label: "Corlake" },
          { value: "300021", label: "Draclake" },
          { value: "300022", label: "Esterbeach" },
          { value: "300023", label: "Fielddale" },
          { value: "300024", label: "Greenloch" },
          { value: "300025", label: "Lochhaven" },
          { value: "300026", label: "Rosemeadow" },
          { value: "300027", label: "Shoreport" },
          { value: "300028", label: "Bluebarrow" },
          { value: "300029", label: "Fielddell" },
          { value: "300030", label: "Lochmead" },
          { value: "300031", label: "Roseport" },
          { value: "300032", label: "Silverash" },
          { value: "300033", label: "Dracmead" },
          { value: "300034", label: "Estercrest" },
          { value: "300035", label: "Fieldmill" },
          { value: "300036", label: "Lochoak" },
          { value: "300037", label: "Rosesea" },
          { value: "300038", label: "Silverbank" },
          { value: "300039", label: "Westerwyn" },
          { value: "300040", label: "Bluedragon" },
          { value: "300041", label: "Corwick" },
          { value: "300042", label: "Dracmeadow" },
          { value: "300043", label: "Greenmill" },
          { value: "300044", label: "Lochville" },
          { value: "300045", label: "Blueelf" },
          { value: "300046", label: "Esterhaven" },
          { value: "300047", label: "Fieldnesse" },
          { value: "300048", label: "Greenness" },
          { value: "300049", label: "Lochwell" },
          { value: "300050", label: "Roseway" },
          { value: "300051", label: "Bluehedge" },
          { value: "300052", label: "Courtdell" },
          { value: "300053", label: "Dracshore" },
          { value: "300054", label: "Fieldport" },
          { value: "300055", label: "Lochwynne" },
          { value: "300056", label: "Marshhill" },
          { value: "300057", label: "Silveredge" },
          { value: "300058", label: "Bluemarble" },
          { value: "300059", label: "Courtlyn" },
          { value: "300060", label: "Dracton" },
          { value: "300061", label: "Loracre" },
          { value: "300062", label: "Marshlake" },
          { value: "300063", label: "Westlea" },
          { value: "300064", label: "Bluesage" },
          { value: "300065", label: "Courtmere" },
          { value: "300066", label: "Dracwick" },
          { value: "300067", label: "Esterness" },
          { value: "300068", label: "Greyacre" },
          { value: "300069", label: "Lorburn" },
          { value: "300070", label: "Meadowedge" },
          { value: "300071", label: "Silverlake" },
          { value: "300072", label: "Westtown" },
          { value: "300073", label: "Bluespell" },
          { value: "300074", label: "Courtmill" },
          { value: "300075", label: "Esteroak" },
          { value: "300076", label: "Greybush" },
          { value: "300077", label: "Lorcastle" },
          { value: "300078", label: "Merriclif" },
          { value: "300079", label: "Silverley" },
          { value: "300080", label: "Westvale" },
          { value: "300081", label: "Bluewall" },
          { value: "300082", label: "Fogcoast" },
          { value: "300083", label: "Greyby" },
          { value: "300084", label: "Lordell" },
          { value: "300085", label: "Merridell" },
          { value: "300086", label: "Silvermage" },
          { value: "300087", label: "Westwell" },
          { value: "300088", label: "Bluewick" },
          { value: "300089", label: "Esterville" },
          { value: "300090", label: "Fogedge" },
          { value: "300091", label: "Greydale" },
          { value: "300092", label: "Lorfield" },
          { value: "300093", label: "Merriton" },
          { value: "300094", label: "Westwilde" },
          { value: "300095", label: "Esterwater" },
          { value: "300096", label: "Fogfield" },
          { value: "300097", label: "Greyfay" },
          { value: "300098", label: "Lorfort" },
          { value: "300099", label: "Wheatbush" },
          { value: "300100", label: "Crystalden" },
          { value: "300101", label: "Esterwick" },
          { value: "300102", label: "Fogland" },
          { value: "200000", label: "Perfect" },
          { value: "200001", label: "Impercourt" },
          { value: "200002", label: "Ashton" },
          { value: "200003", label: "Dorden" },
          { value: "200004", label: "Faydeer" },
          { value: "200005", label: "Linkeep" },
          { value: "200006", label: "Osthill" },
          { value: "200007", label: "Ashness" },
          { value: "200008", label: "Bellshore" },
          { value: "200009", label: "Coldgate" },
          { value: "200010", label: "Dorhall" },
          { value: "200011", label: "Linlea" },
          { value: "200012", label: "Mallowway" },
          { value: "200013", label: "Osthurst" },
          { value: "200014", label: "Redness" },
          { value: "200015", label: "Ashmill" },
          { value: "200016", label: "Ericliff" },
          { value: "200017", label: "Northfield" },
          { value: "200018", label: "Ostley" },
          { value: "200019", label: "Redrose" },
          { value: "200020", label: "Ashwick" },
          { value: "200021", label: "Belmead" },
          { value: "200022", label: "Coldlea" },
          { value: "200023", label: "Dorhill" },
          { value: "200024", label: "Erigate" },
          { value: "200025", label: "Faygate" },
          { value: "200026", label: "Linport" },
          { value: "200027", label: "Mapleley" },
          { value: "200028", label: "Ostmeadow" },
          { value: "200029", label: "Westerby" },
          { value: "200030", label: "Coldloch" },
          { value: "200031", label: "Erihall" },
          { value: "200032", label: "Faylake" },
          { value: "200033", label: "Northmoor" },
          { value: "200034", label: "Ostport" },
          { value: "200035", label: "Redwolf" },
          { value: "200036", label: "Violetmont" },
          { value: "200037", label: "Belwall" },
          { value: "200038", label: "Coldmount" },
          { value: "200039", label: "Dorport" },
          { value: "200040", label: "Erilea" },
          { value: "200041", label: "Fayland" },
          { value: "200042", label: "Grasspond" },
          { value: "200043", label: "Northpond" },
          { value: "200044", label: "Ostwynne" },
          { value: "200045", label: "Belwick" },
          { value: "200046", label: "Erimarsh" },
          { value: "200047", label: "Fayness" },
          { value: "200048", label: "Grassshore" },
          { value: "200049", label: "Lochbank" },
          { value: "200050", label: "Northriver" },
          { value: "200051", label: "Violetsage" },
          { value: "200052", label: "Westerfox" },
          { value: "200053", label: "Blackfield" },
          { value: "200054", label: "Dorwall" },
          { value: "200055", label: "Faypine" },
          { value: "200056", label: "Grasstown" },
          { value: "200057", label: "Lochbell" },
          { value: "200058", label: "Northwood" },
          { value: "200059", label: "Rosecliff" },
          { value: "200060", label: "Shadowston" },
          { value: "200061", label: "Gala" },
          { value: "200062", label: "Alethea" },
          { value: "200063", label: "Omega" },
          { value: "200064", label: "Gustava" },
          { value: "200065", label: "Agnete" },
          { value: "200066", label: "Katana" },
          { value: "200067", label: "Avishai" },
          { value: "200068", label: "Aulis" },
          { value: "200069", label: "Nordica" },
          { value: "200070", label: "Paradise" },
          { value: "200071", label: "Legna" },
          { value: "200072", label: "Bramwell" },
          { value: "200073", label: "Caliban" },
          { value: "200074", label: "Waylon" },
          { value: "200075", label: "Rowan" },
          { value: "200076", label: "Vincent" },
          { value: "200077", label: "Nathaniel" },
          { value: "200078", label: "George" },
          { value: "200079", label: "Miko" },
          { value: "200080", label: "Gavin" },
          { value: "200081", label: "Calvin" },
          { value: "200082", label: "Hayden" },
          { value: "200083", label: "Graham" },
          { value: "200084", label: "Zayden" },
          { value: "200085", label: "Barrett" },
          { value: "200086", label: "Tucker" },
          { value: "200087", label: "Timothy" },
          { value: "200088", label: "Edward" },
          { value: "200089", label: "Victor" },
          { value: "200090", label: "Charlie" },
          { value: "200091", label: "Peter" },
          { value: "200092", label: "Oscar" },
          { value: "200093", label: "Matias" },
          { value: "200094", label: "Lukas" },
          { value: "200095", label: "Remington" },
          { value: "200096", label: "Brantley" },
          { value: "200097", label: "Bryce" },
          { value: "200098", label: "Mark" },
          { value: "200099", label: "Paxton" },
          { value: "200100", label: "Ronan" },
          { value: "200101", label: "Walter" },
          { value: "200102", label: "Chance" },
          { value: "200103", label: "Malcolm" },
          { value: "200104", label: "Francisco" },
          { value: "200105", label: "Derek" },
          { value: "200106", label: "Narthex" },
          { value: "200107", label: "Achelous" },
          { value: "200108", label: "Ares" },
          { value: "200109", label: "Chryseis" },
          { value: "200110", label: "Eteocles" },
          { value: "200111", label: "Hyperion" },
          { value: "200112", label: "Moon" },
          { value: "200113", label: "Phoebe" },
          { value: "200114", label: "Stentor" },
          { value: "200115", label: "Chaos" },
          { value: "200116", label: "Achilles" },
          { value: "200117", label: "Aries" },
          { value: "200118", label: "Clymene" },
          { value: "200119", label: "Europe" },
          { value: "200120", label: "Iapetus" },
          { value: "200121", label: "Neleus" },
          { value: "200122", label: "Pisces" },
          { value: "200123", label: "Sun" },
          { value: "200124", label: "Triteia" },
          { value: "200125", label: "Admetus" },
          { value: "200126", label: "Artemis" },
          { value: "200127", label: "Eurynome" },
          { value: "200128", label: "Icarus" },
          { value: "200129", label: "Nemesis" },
          { value: "200130", label: "Polynices" },
          { value: "200131", label: "Syrinx" },
          { value: "200132", label: "Persephone" },
          { value: "200133", label: "Adrastus" },
          { value: "200134", label: "Atalanta" },
          { value: "200135", label: "Coeus" },
          { value: "200136", label: "Eurystheus" },
          { value: "200137", label: "Io" },
          { value: "200138", label: "Neptune" },
          { value: "200139", label: "Poseidon" },
          { value: "200140", label: "Talos" },
          { value: "200141", label: "Agaue" },
          { value: "200142", label: "Aeetes" },
          { value: "200143", label: "Atreus" },
          { value: "200144", label: "Gaea" },
          { value: "200145", label: "Ixion" },
          { value: "200146", label: "Nesfor" },
          { value: "200147", label: "Priams" },
          { value: "200148", label: "Tantalus" },
          { value: "200149", label: "Vulcanus" },
          { value: "200150", label: "Aeneas" },
          { value: "200151", label: "Auriga" },
          { value: "200152", label: "Crius" },
          { value: "200153", label: "Jasion" },
          { value: "200154", label: "Nessus" },
          { value: "200155", label: "Pyrrla" },
          { value: "200156", label: "Telemachus" },
          { value: "200157", label: "Aeolus" },
          { value: "200158", label: "Bio" },
          { value: "200159", label: "Crotos" },
          { value: "200160", label: "Hebe" },
          { value: "200161", label: "Jason" },
          { value: "200162", label: "Nireus" },
          { value: "200163", label: "Pyxis" },
          { value: "200164", label: "Teucer" },
          { value: "200165", label: "Iapetos" },
          { value: "200166", label: "Agamemnon" },
          { value: "200167", label: "Cadmus" },
          { value: "200168", label: "Cygnus" },
          { value: "200169", label: "Hector" },
          { value: "200170", label: "Norma" },
          { value: "200171", label: "Rhea" },
          { value: "200172", label: "Thaumas" },
          { value: "200173", label: "Ajax" },
          { value: "200174", label: "Caelum" },
          { value: "200175", label: "Daedalus" },
          { value: "200176", label: "Helen" },
          { value: "200177", label: "Ladon" },
          { value: "200178", label: "Octans" },
        ],
      },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Jades yang kamu inginkan",
      "Masukkan User ID dan Server ID anda",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Mirage:Perfect Skyline kamu",
    ],
  },

  "Never After (Global)": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("Role ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Role ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal produk yang kamu inginkan",
      "Masukkan User ID anda",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Never After (Global) kamu",
    ],
  },
  "Perfect World": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Koin yang kamu inginkan",
      "Masukkan User ID dan Server ID anda",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Perfect World kamu",
    ],
  },
  "Revelation Mobile": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal produk yang kamu inginkan",
      "Masukkan User ID anda",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Revelation Mobile kamu",
    ],
  },
  "Sausage Man": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Candy yang kamu inginkan",
      "Masukkan User ID anda",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Sausage Man kamu",
    ],
  },
  "Hay Day": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("Player Tag is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "Player Tag", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal produk yang kamu inginkan",
      "Masukkan Player Tag anda",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Player Tag kamu",
    ],
  },
  "Command & Conquer™: Legions": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      { name: "username", label: "Username", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [
      { value: "battlefield-1", label: "Battlefield-1" },
      { value: "battlefield-2", label: "Battlefield-2" },
      { value: "battlefield-3", label: "Battlefield-3" },
      { value: "battlefield-4", label: "Battlefield-4" },
      { value: "battlefield-5", label: "Battlefield-5" },
      { value: "battlefield-6", label: "Battlefield-6" },
      { value: "battlefield-7", label: "Battlefield-7" },
      { value: "battlefield-8", label: "Battlefield-8" },
      { value: "battlefield-9", label: "Battlefield-9" },
      { value: "battlefield-10", label: "Battlefield-10" }
    ],
      },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Pesanan akan ditambahkan ke akun Command & Conquer: Legions kamu",
    ],
  },
  "EVE Echoes": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
    name: "zoneID",
    label: "Server ID",
    type: "select",
    options: [{ value: "Aurora", label: "Aurora" }],
  },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun EVE Echoes kamu",
    ],
  },
  "Tom and Jerry: Chase": {
    schema: Yup.object().shape({
      selectedDenom: Yup.string().required("Please select a package"),
      userID: Yup.string().required("User ID is required"),
      zoneID: Yup.string().required("Server ID is required"),
      paymentMethod: Yup.string().required("Please select a payment method"),
    }),
    formFields: [
      { name: "userID", label: "User ID", type: "text" },
      {
        name: "zoneID",
        label: "Server ID",
        type: "select",
        options: [{ value: "asia_server", label: "Asia Server" }],
      },
      { name: "username", label: "Username", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
    ],
    caraTopUp: [
      "Pilih Nominal Produk yang kamu inginkan ",
      "Masukkan User ID",
      "Selesaikan pembayaran",
      "Produk akan ditambahkan ke akun Tom and Jerry: Chase kamu",
    ],
  },
};

export default productsConfig;
