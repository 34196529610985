import React from "react";
import HeaderTwo from "../../components/header/HeaderTwo";
import Footer from "../../components/footer/Footer";
import TopUp from "./TopUp";

function TopUpDetails() {
  return (
    <>
      <HeaderTwo />
      <main>
        <TopUp/>
      </main>
      <Footer />
    </>
  );
}

export default TopUpDetails;
