import React from 'react'
import HeaderTwo from '../../components/header/HeaderTwo'
import Footer from '../../components/footer/Footer'
import LoginRe from './LoginRe'

function LoginREdetails() {
  return (
    <>
    <HeaderTwo/>
    <main>
        <LoginRe/>
    </main>
    <Footer/>
    </>
  )
}

export default LoginREdetails