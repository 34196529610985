import React from 'react'
import HeaderTwo from '../../components/header/HeaderTwo'
import Privacy from './Privacy'
import Footer from '../../components/footer/Footer'
import PrivacyBreadCrumb from './PrivacyBreadCrumb'

function PrivacyDetail() {
  return (
    <>
    <HeaderTwo/>
    <main>
        <PrivacyBreadCrumb/>
        <Privacy/>
    </main>
    <Footer/>
    </>
  )
}

export default PrivacyDetail