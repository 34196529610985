import React from "react";

const faqData = [
  {
    id: "what-is-nerogames.id",
    question: "1. Apa itu Nero Games?",
    answer: "Nero Games adalah platform satu atap Anda untuk game online berbasis web dan layanan top-up game mobile. Kami menyediakan berbagai macam game online dan menawarkan layanan top-up untuk game mobile populer seperti Mobile Legends: Bang Bang (MLBB) dan PUBG.",
  },
  {
    id: "how-do-i-create-an-account",
    question: "2. Bagaimana cara membuat akun?",
    answer: 'Untuk membuat akun, klik tombol "Login" di pojok kanan atas halaman utama. Kemudian klik "Daftar Sekarang" dan isi detail yang diperlukan. Ikuti petunjuk untuk menyelesaikan pendaftaran Anda.',
  },
  {
    id: "how-can-i-top-up-my-game-balance",
    question: "3. Bagaimana cara top-up saldo game saya?",
    answer: 'Untuk top-up saldo game Anda, masuk ke akun Nero Games Anda, klik bagian "Top Up", pilih game Anda, masukkan detail yang diperlukan, pilih metode pembayaran, dan konfirmasi transaksi.',
  },
  {
    id: "what-payment-methods-do-you-accept",
    question: "4. Metode pembayaran apa saja yang diterima?",
    answer: "Kami menerima berbagai metode pembayaran, termasuk kartu kredit/debit, e-wallet, dan QRIS untuk transaksi dalam mata uang IDR.",
  },
  {
    id: "how-long-does-it-take-for-the-top-up-to-reflect-in-my-game-account",
    question: "5. Berapa lama waktu yang dibutuhkan untuk top-up muncul di akun game saya?",
    answer: "Transaksi top-up biasanya diproses secara instan. Dalam kasus yang jarang terjadi, mungkin membutuhkan beberapa menit. Jika top-up Anda tidak muncul di akun game dalam waktu yang wajar, silakan hubungi tim dukungan kami.",
  },
  {
    id: "what-should-i-do-if-my-top-up-fails",
    question: "6. Apa yang harus saya lakukan jika top-up saya gagal?",
    answer: "Jika top-up Anda gagal, periksa detail pembayaran Anda dan coba lagi. Jika masalah berlanjut, hubungi tim dukungan kami dengan detail transaksi Anda untuk bantuan lebih lanjut.",
  },
  {
    id: "can-i-get-a-refund-for-a-top-up",
    question: "7. Bisakah saya mendapatkan pengembalian dana untuk top-up?",
    answer: "Semua transaksi top-up bersifat final dan tidak dapat dikembalikan. Pastikan Anda memasukkan detail yang benar sebelum mengonfirmasi transaksi Anda.",
  },
  {
    id: "do-you-offer-any-promotions-or-discounts",
    question: "8. Apakah Anda menawarkan promosi atau diskon?",
    answer: "Ya, kami secara berkala menawarkan promosi dan diskon untuk top-up game. Pantau situs web kami dan saluran media sosial untuk penawaran terbaru.",
  },
  {
    id: "how-can-i-become-a-reseller-for-mobile-game-top-ups",
    question: "9. Bagaimana cara menjadi reseller top-up game mobile?",
    answer: "Untuk menjadi reseller, silakan hubungi kami melalui halaman dukungan dengan detail bisnis Anda. Tim kami akan menghubungi Anda untuk membahas peluang dan persyaratan.",
  },
  {
    id: "how-can-i-contact-customer-support",
    question: "10. Bagaimana cara menghubungi dukungan pelanggan?",
    answer: "Anda dapat menghubungi tim dukungan pelanggan kami dengan mengirim email ke support@nerogames.id atau melalui formulir kontak di situs web kami. Kami siap membantu Anda dengan masalah atau pertanyaan apa pun yang mungkin Anda miliki.",
  },
  {
    id: "are-my-personal-and-payment-details-secure",
    question: "11. Apakah detail pribadi dan pembayaran saya aman?",
    answer: "Ya, kami memprioritaskan keamanan informasi pribadi dan pembayaran Anda. Kami menggunakan teknologi enkripsi canggih untuk memastikan data Anda aman dan terlindungi.",
  },
];

function FaQ() {
  return (
    <div className="bg-all-container">
      <div className="faq-wrapper">
        <div className="Content-Wrapper flex flex-col items-start gap-y-6">
          {faqData.map((faq, index) => (
            <div key={index} className="flex flex-col gap-y-2 items-start">
              <h3 id={faq.id} className="text-xl sm:text-2xl lg:text-3xl font-medium">{faq.question}</h3>
              <p style={{ color: "white" }} className="text-sm lg:text-base leading-relaxed">{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FaQ;
