import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilUser, cilCommentSquare, cilLockLocked } from "@coreui/icons";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import API from "../../api";

const ForgotPassword = () => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState("");
  const [question, setQuestion] = useState("");
  const [idQuestion, setIdQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleUsernameSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await API.post("user-check", { username });
      if (
        response.status === "Bad Request" ||
        response.status === "Server Error"
      ) {
        if (response.errors) {
          response.errors.forEach((val) => {
            toast.error(val.msg, {
              position: "top-right",
            });
          });
        } else {
          toast.error(response.message, {
            position: "top-right",
          });
        }
      } else {
        setQuestion(response.question);
        setIdQuestion(response.id_question);
        setStep(2);
      }
    } catch (error) {
      toast.error("Username not found", {
        position: "top-right",
      });
    }
  };

  const handleAnswerSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await API.post("answer-check", {
        username,
        recovery_question: idQuestion,
        recovery_answer: answer,
      });

      if (
        response.status === "Bad Request" ||
        response.status === "Server Error"
      ) {
        if (response.errors) {
          response.errors.forEach((val) => {
            toast.error(val.msg, {
              position: "top-right",
            });
          });
        } else {
          toast.error(response.message, {
            position: "top-right",
          });
        }
      } else {
        setStep(3);
      }
    } catch (error) {
      toast.error("Incorrect answer", {
        position: "top-right",
      });
    }
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await API.post("reset-password", {
        username,
        recovery_question: idQuestion,
        recovery_answer: answer,
        password: newPassword,
      });

      if (
        response.status === "Bad Request" ||
        response.status === "Server Error"
      ) {
        if (response.errors) {
          response.errors.forEach((val) => {
            toast.error(val.msg, {
              position: "top-right",
            });
          });
        } else {
          toast.error(response.message, {
            position: "top-right",
          });
        }
      } else {
        toast.success("Password reset successfully", {
          position: "top-right",
        });
        history.push("/login");
      }
    } catch (error) {
      toast.error("Error resetting password", {
        position: "top-right",
      });
    }
  };

  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCard>
              <CCardBody>
                <CForm
                  onSubmit={
                    step === 1
                      ? handleUsernameSubmit
                      : step === 2
                      ? handleAnswerSubmit
                      : handlePasswordSubmit
                  }
                >
                  <h1>Forgot Password</h1>
                  {step === 1 && (
                    <>
                      <p className="text-body-secondary">Enter your username</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          placeholder="Username"
                          autoComplete="username"
                          required
                        />
                      </CInputGroup>
                      <CButton color="primary" className="px-4" type="submit">
                        Next
                      </CButton>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <p className="text-body-secondary">
                        Answer the recovery question
                      </p>
                      <p>{question}</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilCommentSquare} />
                        </CInputGroupText>
                        <CFormInput
                          value={answer}
                          onChange={(e) => setAnswer(e.target.value)}
                          placeholder="Answer"
                          autoComplete="answer"
                          required
                        />
                      </CInputGroup>
                      <CButton color="primary" className="px-4" type="submit">
                        Next
                      </CButton>
                    </>
                  )}
                  {step === 3 && (
                    <>
                      <p className="text-body-secondary">
                        Enter your new password
                      </p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          type="password"
                          placeholder="New Password"
                          autoComplete="new-password"
                          required
                        />
                      </CInputGroup>
                      <CButton color="primary" className="px-4" type="submit">
                        Save
                      </CButton>
                    </>
                  )}
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
