import React from "react";

const tncData = [
  {
    id: "introduction",
    title: "1. Pendahuluan",
    content: "Selamat datang di NeroGames.id. Dengan mengakses dan menggunakan situs web kami, Anda setuju untuk mematuhi dan terikat oleh syarat dan ketentuan ini. Harap baca dengan cermat."
  },
  {
    id: "user-accounts",
    title: "2. Akun Pengguna",
    content: "Untuk mengakses fitur tertentu, Anda mungkin perlu membuat akun. Anda bertanggung jawab untuk menjaga kerahasiaan informasi akun Anda dan untuk semua aktivitas yang terjadi di bawah akun Anda."
  },
  {
    id: "use-of-our-services",
    title: "3. Penggunaan Layanan Kami",
    content: "Anda setuju untuk menggunakan situs web dan layanan kami hanya untuk tujuan yang sah. Anda tidak boleh menggunakan situs kami dengan cara apa pun yang melanggar hukum atau peraturan lokal, nasional, atau internasional yang berlaku."
  },
  {
    id: "top-up-transactions",
    title: "4. Transaksi Top-Up",
    content: "Semua transaksi top-up bersifat final. Pastikan Anda telah memasukkan detail dengan benar sebelum mengkonfirmasi transaksi. NeroGames.id tidak bertanggung jawab atas entri yang salah atau penggunaan akun Anda yang tidak sah."
  },
  {
    id: "intellectual-property",
    title: "5. Hak Kekayaan Intelektual",
    content: "Semua konten, merek dagang, logo, dan hak kekayaan intelektual lainnya yang ditampilkan di situs web kami adalah milik NeroGames.id. Anda tidak diizinkan menggunakan ini tanpa persetujuan tertulis dari kami sebelumnya."
  },
  {
    id: "privacy-policy",
    title: "6. Kebijakan Privasi",
    content: "Privasi Anda penting bagi kami. Silakan tinjau Kebijakan Privasi kami untuk memahami bagaimana kami mengumpulkan, menggunakan, dan melindungi informasi pribadi Anda."
  },
  {
    id: "limitation-of-liability",
    title: "7. Batasan Tanggung Jawab",
    content: "NeroGames.id tidak bertanggung jawab atas kerugian langsung, tidak langsung, insidental, atau konsekuensial yang timbul dari penggunaan situs web atau layanan kami."
  },
  {
    id: "changes-to-terms",
    title: "8. Perubahan Syarat",
    content: "Kami berhak untuk mengubah syarat dan ketentuan ini kapan saja. Perubahan akan berlaku segera setelah diposting di situs web kami. Penggunaan Anda yang berkelanjutan di situs ini menunjukkan penerimaan Anda terhadap syarat yang baru."
  },
  {
    id: "contact-us",
    title: "9. Hubungi Kami",
    content: "Jika Anda memiliki pertanyaan tentang syarat dan ketentuan ini, silakan hubungi kami di support@nerogames.id."
  }
];

function TnC() {
  return (
    <div className="bg-all-container">
      <div className="TnC-wrapper">
        <div className="Content-Wrapper flex flex-col items-start gap-y-6">
          {tncData.map((item, index) => (
            <div key={index}>
              <h2 id={item.id} className="text-xl sm:text-2xl lg:text-3xl font-medium">{item.title}</h2>
              <p style={{ color: "white" }} className="text-sm lg:text-base leading-relaxed">{item.content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TnC;
