import Fade from "react-reveal/Fade";

import Bg from "../../../assets/img/Home/hero_bg.jpg";
import HeroImg from "../../../assets/img/Home/slider-home.png";
import CircleImg from "../../../assets/img/slider/slider_circle.png";

export default function HeroSection() {
    return (
        <section
            className="relative w-full h-full lg:min-h-[820px] min-h-[650px] bg-no-repeat flex items-center lg:justify-center justify-end antialiased overflow-hidden"
            style={{
                backgroundImage: `url(${Bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        >
            <div className="flex items-center justify-center lg:pl-8 lg:pr-0 px-4 h-full w-full">
                <div className="text-white leading-8 flex flex-col gap-10">
                    <div className="text-center base:text-left flex flex-col items-start justify-start gap-2 lg:gap-0 max-w-[30rem] lg:max-w-full">
                        <Fade up delay={.4000}>
                            <h6 className="font-bold text-xl sm:text-2xl tracking-tighter uppercase">
                                Top Up Akun Nero Games Anda Secara Instan
                            </h6>
                        </Fade>

                        <Fade up delay={.5000}>
                            <h1 className="font-extrabold text-[#E4A101] text-4xl lg:text-[68px] xl:text-[80px] leading-1 lg:leading-[4.7rem] tracking-tighter uppercase">
                                Nero Games <br /> Top Up Mudah &amp; Aman
                            </h1>
                        </Fade>



                        <Fade up delay={.5000}>
                            <p className='text-white text-sm sm:text-base leading-5'>
                                Kami menawarkan cara yang cepat, aman, dan nyaman untuk mengisi ulang akun game Anda tanpa kerumitan.
                            </p>
                        </Fade>
                    </div>
                </div>


                <div className="z-10 aspect-auto relative">
                    <img src={HeroImg} alt="hero" className="lg:w-[1000px] md:w-96 w-72 h-full bg-center" loading="lazy" />
                </div>

                <div className="absolute left-0 top-0 opacity-10 z-0 lg:w-[800px] w-96 flex items-center justify-center overflow-hidden">
                    <img src={CircleImg} alt="Rotating Circle" className="w-full animate-spin-slow" />
                </div>
            </div>
        </section>
    )
}
