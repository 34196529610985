import React from 'react';

const WhatsupBtn = () => {
  return (
    <a href="https://api.whatsapp.com/send?phone=6281367802464" id='side-btn-whatsapp' target="_blank" rel="noopener noreferrer" style={styles.link}>
      <img
      id='side-btn-whatsapp'
        src="https://storage.googleapis.com/nero-assets/socmed/WA-Icon.jpg"
        alt="WhatsApp"
        style={styles.icon}
      />
    </a>
  );
};

const styles = {
  link: {
    position: 'fixed',
    bottom: '70px',
    left: '0px',
    zIndex: 1000,
  },
  icon: {
    width: '50px', // Adjust size as needed
    height: '50px', // Adjust size as needed
  }
};

export default WhatsupBtn;
