import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import "../../assets/css/style.css";
import API from "../../api";
import SUGAR from "../../assets/img/Home/SUGAR.png";
import IDLE from "../../assets/img/Home/IDLE.png";
import { CSpinner, CAlert } from "@coreui/react";
import { FormControl, Button } from "react-bootstrap"; // Import Bootstrap components

// const neroGames = [
//   { id: "sugarpuffs", name: "SUGAR", image: SUGAR },
//   { id: "idlemarkets", name: "IDLE", image: IDLE },
//   { id: "jetpack", name: "JET PACK", image: "https://storage.googleapis.com/nero-assets/nero-game-logo/JET.webp" },
//   { id: "bitcoinclicker", name: "BITCOIN CLICKER", image: "https://storage.googleapis.com/nero-assets/nero-game-logo/BILLIONAIRE.webp" },
// ];

// const TopupSchema = Yup.object().shape({
//   username: Yup.string().required("Username is required"),
//   phoneNumber: Yup.string().required("Phone Number is required"),
//   userID: Yup.string().required("User ID is required"),
//   amount: Yup.number()
//     .required("Amount is required")
//     .min(10000, "Amount must be at least 10,000"),
//   game: Yup.string().required("Please select a game"),
//   ref_id: Yup.string().optional(),
// });

const TopUpPage = () => {
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]); // State for filtered products
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [initialValues, setInitialValues] = useState({
    username: "",
    phoneNumber: "",
    userID: "",
    amount: "",
    game: "",
    ref_id: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get("retrieveDTU");
        if (response.data.response.status === "200") {
          const productsList = response.data.response.list_dtu;
          setProducts(productsList);
          setFilteredProducts(productsList); // Initialize filtered products with full list
        } else {
          setError(response.data.message || "Failed to fetch products");
        }
      } catch (error) {
        setError("An error occurred while fetching products");
      } finally {
        setLoading(false);
      }
    };

    // const fetchUserData = async () => {
    //   try {
    //     const response = await API.post("get-user");
    //     if (response?.user) {
    //       const loggedInUsername = localStorage.getItem("username");
    //       // const user = response.data.users.find(
    //       //   (user) => user.username === loggedInUsername
    //       // );
    //       if (response?.user) {
    //         setInitialValues((prevValues) => ({
    //           ...prevValues,
    //           username: response?.user.username,
    //           userID: response?.user.id,
    //         }));
    //       } else {
    //         setError("User not found");
    //       }
    //     } else {
    //       setError(response.data.message || "Failed to fetch user data");
    //     }
    //   } catch (error) {
    //     setError("An error occurred while fetching user data");
    //   }
    // };

    fetchData();
    // fetchUserData();
  }, []);

  useEffect(() => {
    // Filter products based on the search query
    if (searchQuery === "") {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(
        products.filter((product) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
  }, [searchQuery, products]);

  const handleGameClick = (gameName) => {
    history.push(`/product/${gameName}`);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  if (loading) {
    return (
      <div className="min-vh-100 d-flex justify-content-center align-items-center">
        <CSpinner color="primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-vh-100 d-flex justify-content-center align-items-center">
        <CAlert color="danger">{error}</CAlert>
      </div>
    );
  }

  return (
    <div className="top-up-page shop-area">
      {/* <div className="top-up-wrapper" style={{ backgroundColor:'#2f002cd6', borderRadius:'12px'}}>
        <Formik
          initialValues={initialValues}
          validationSchema={TopupSchema}
          onSubmit={(values) => {
            history.push({
              pathname: `/payment/${values.userID}`,
              state: values,
            });
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <h4
                style={{ color: "white", fontWeight: "bold", fontSize: "32px", padding:10 }}
              >
                Select Nero Games
              </h4>
              <div className="Game">
                <ul>
                  {neroGames.map((game) => (
                    <li key={game.id}>
                      <Field
                        type="radio"
                        name="game"
                        value={game.id}
                        id={game.id}
                        className="form-check-input"
                      />
                      <label htmlFor={game.id}>
                        <img style={{borderRadius:12}} src={game.image} alt={game.name} />
                      </label>
                    </li>
                  ))}
                </ul>
                <ErrorMessage
                  name="game"
                  component="div"
                  className="text-danger danger f16px"
                />
              </div>
              <div className="form-container">
                <div className="left">
                  <div className="wrapper">
                    <h4
                      className="text-nowrap"
                      style={{ color: "black", fontSize: 14 }}
                    >
                      Username
                    </h4>
                    <div className="margin-40">
                      <Field
                        name="username"
                        className="form-control"
                        placeholder="Username"
                      />
                      <ErrorMessage
                        name="username"
                        component="div"
                        className="text-danger danger absolute"
                      />
                    </div>
                  </div>
                  <div className="wrapper">
                    <h4
                      className="text-nowrap"
                      style={{ color: "black", fontSize: 14 }}
                    >
                      Phone Number
                    </h4>
                    <div className="margin-20">
                      <Field
                        name="phoneNumber"
                        className="form-control"
                        placeholder="Phone Number"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="text-danger danger absolute"
                      />
                    </div>
                  </div>
                </div>
                <div className="right">
                  <div className="wrapper">
                    <h4
                      className="text-nowrap"
                      style={{ color: "black", fontSize: 14 }}
                    >
                      User ID
                    </h4>
                    <div className="margin-40">
                      <Field
                        name="userID"
                        className="form-control"
                        placeholder="User ID"
                      />
                      <ErrorMessage
                        name="userID"
                        component="div"
                        className="text-danger danger absolute"
                      />
                    </div>
                  </div>
                  <div className="wrapper">
                    <h4
                      className="text-nowrap"
                      style={{ color: "black", fontSize: 14 }}
                    >
                      Input Amount
                    </h4>
                    <div className="marginL-20">
                      <Field name="amount" className="form-control" />
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="text-danger danger absolute"
                      />
                    </div>
                  </div>
                  <div className="wrapper">
                    <h4
                      className="text-nowrap"
                      style={{ color: "black", fontSize: 14 }}
                    >
                      Referral ID
                    </h4>
                    <div className="marginA-40">
                      <Field name="ref_id" className="form-control" placeholder="(Optional)" />
                      <ErrorMessage
                        name="ref_id"
                        component="div"
                        className="text-danger danger absolute"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <br />
                <div className="row" style={{ justifyContent: "flex-end" }}>
                  <div className="button-container">
                    <button
                    id="Submit-btn-nero"
                    className="margin-b-10"
                      style={{
                        padding: "5px 15px",
                        border: "1px solid black",
                        borderRadius: "16px",
                        marginTop: 10,
                      }}
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div> */}
      
      <div className="search-container top-up-wrapper" style={{backgroundColor:'#2f002cd6', borderRadius:'12px' ,marginBottom: "20px",marginTop:'20px' }}>
        <FormControl
          type="search"
          placeholder="Cari Permainan..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <div className="top-up-wrapper" style={{ backgroundColor:'#2f002cd6', borderRadius:'12px', marginTop:'20px'}}>
        <h4 style={{ color: "white", fontWeight: "bold", fontSize: "32px",padding:10 }}>Top Up Voucher</h4>
        <div className="Game">
          <ul>
            {filteredProducts.map((game) => (
              <li
                key={game.id}
                className="game-card"
                onClick={() => handleGameClick(game.name)}
              >
                <label style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  <img
                    src={game.image}
                    alt={game.name}
                    style={{ cursor: "pointer", borderRadius:'10px' }}
                  />
                  <span style={{color:'white'}}>{game.name}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TopUpPage;
