import React from 'react'
import HeaderTwo from '../../components/header/HeaderTwo'
import Aboutus from './Aboutus'
import Footer from '../../components/footer/Footer'
import AboutusBreadcrumb from './AboutusBreadcrumb'

function AboutDetail() {
  return (
    <>
    <HeaderTwo/>
    <main>
        <Aboutus/>
    </main>
    <Footer/>
    </>
  )
}

export default AboutDetail