import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import API from "../../api";
import {
  CCard,
  CCardBody,
  CContainer,
  CSpinner,
  CAlert,
  CButton,
} from "@coreui/react";

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await API.post("get-user");
        if (response) {
          setUserData(response?.user);
        } else {
          setError(response?.data?.message || "Failed to fetch user data");
        }
      } catch (error) {
        setError("An error occurred while fetching user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const navigateToHistory = () => {
    if (userData) {
      history.push(`/transaction-history/${userData.id}`);
    }
  };

  if (loading) {
    return (
      <CContainer className="min-vh-100 d-flex justify-content-center align-items-center">
        <CSpinner color="primary" />
      </CContainer>
    );
  }

  if (error) {
    return (
      <CContainer className="min-vh-100 d-flex justify-content-center align-items-center bg-all-container">
        <CAlert color="danger">{error}</CAlert>
      </CContainer>
    );
  }

  return (
    <div className="min-vh-100 d-flex justify-content-center align-items-center bg-all-container">
      <div className="rgb-background">
        <CCard className="profile-card">
          <CCardBody>
            <div style={{ gap: 10 }} className="d-flex w-100">
              <div className="profile-header">
                <h2>Welcome {userData.username}</h2>
              </div>
              <div className="profile-header">
                <h2>
                  Nero Coin:{" "}
                  <span style={{ color: "orange", fontWeight: "bold" }}>
                    {userData.amount}
                  </span>
                </h2>
                <CButton className="game-button" href="/top-up-game-coin">Select Game</CButton>
              </div>
            </div>
            <div className="profile-details">
              <div className="detail-header">
                <div className="detail">
                  <strong>Username</strong>
                  <strong>User ID</strong>
                  {userData.nik && <strong>Ref ID</strong>}
                </div>
              </div>
              <div className="detail-body">
                <div className="detail">
                  <strong>{userData.username}</strong>
                  <strong style={{ color: "yellow" }}>{userData.id}</strong>
                  {userData.nik && (
                    <strong style={{ color: "yellow" }}>
                      {userData.ref_id ? userData.ref_id : "Ref ID is not set"}
                    </strong>
                  )}
                </div>
              </div>
            </div>
            {userData.role.toLowerCase() === "agent" && (
              <div className="profile-notif">
                <p style={{ color: "red" }}>
                  *Please ask support for set up the referral ID and credentials
                  for the dashboard page
                </p>
              </div>
            )}
            <div className="profile-actions">
              {userData.role.toLowerCase() === "agent" ? (
                <>
                  <CButton
                    color="primary"
                    className="profile-button"
                    onClick={() =>
                      window.open("https://dashboard.nerogames.id/", "_blank")
                    }
                  >
                    Dashboard
                  </CButton>
                  <CButton
                    color="primary"
                    className="profile-button"
                    onClick={() =>
                      window.open(
                        "https://api.whatsapp.com/send?phone=6281367802464",
                        "_blank"
                      )
                    }
                  >
                    Contact Support
                  </CButton>
                </>
              ) : (
                <>
                  <CButton
                    color="primary"
                    className="profile-button"
                    onClick={() =>
                      window.open(
                        "https://api.whatsapp.com/send?phone=6281367802464",
                        "_blank"
                      )
                    }
                  >
                    Contact Support
                  </CButton>
                  <CButton
                    href="/top-up"
                    color="primary"
                    className="profile-button max-w-160px"
                  >
                    Top Up
                  </CButton>
                  <CButton
                    href="/shop"
                    color="primary"
                    className="profile-button"
                  >
                    Games
                  </CButton>
                  <CButton
                    color="primary"
                    className="profile-button max-w-160px"
                    onClick={navigateToHistory}
                  >
                    Transaction History
                  </CButton>
                  <CButton
                    color="primary"
                    className="profile-button full-width-button"
                    href="/top-up-coin"
                  >
                    Top Up Nero Coin
                  </CButton>
                </>
              )}
            </div>
          </CCardBody>
        </CCard>
      </div>
    </div>
  );
};

export default Profile;
