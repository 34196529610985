import React from 'react'
import HeaderTwo from '../../components/header/HeaderTwo'
import FPassword from './FPassword'
import Footer from '../../components/footer/Footer'

function FpasswordDetail() {
  return (
    <>
    <HeaderTwo/>
    <main>
        <FPassword/>
    </main>
    <Footer/>
    </>
  )
}

export default FpasswordDetail