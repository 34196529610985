import React from 'react'
import HeaderTwo from '../../components/header/HeaderTwo'
import Footer from '../../components/footer/Footer'
import Login from './Login'
function LoginDetail() {
  return (
    <>
    <HeaderTwo/>
    <main>
        <Login/>
    </main>
    <Footer/>
    </>
  )
}

export default LoginDetail