import React from 'react'
import HeaderTwo from '../../components/header/HeaderTwo'
import Refund from './Refund'
import Footer from '../../components/footer/Footer'
import RefundBreadcrumb from './RefundBreadcrumb'

function RefundDetail() {
  return (
    <>
    <HeaderTwo/>
    <main>
        <RefundBreadcrumb/>
        <Refund/>
    </main>
    <Footer/>
    </>
  )
}

export default RefundDetail