import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../api';
import {
  CContainer,
  CSpinner,
  CAlert,
  CCard,
  CCardBody,
  CCardHeader,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";

const ThistoryDetails = () => {
  const { userID } = useParams();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      const user_id_nero = localStorage.getItem('user_id_nero');

      try {
        const response = await API.get(
          `payments?limit=100&offset=0&user_id_nero=${user_id_nero}`
        );
        if (response.status === 200) {
          setTransactions(response.data.payments);
        } else {
          setError(response.data.message || "Failed to fetch transactions");
        }
      } catch (error) {
        setError("An error occurred while fetching transactions");
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [userID]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(amount);
  };

  if (loading) {
    return (
      <CContainer className="min-vh-100 d-flex justify-content-center align-items-center">
        <CSpinner color="primary" />
      </CContainer>
    );
  }

  if (error) {
    return (
      <CContainer className="min-vh-100 d-flex justify-content-center align-items-center bg-all-container">
        <CAlert color="danger">{error}</CAlert>
      </CContainer>
    );
  }

  return (
    <div className="min-vh-100 d-flex flex-column justify-content-center align-items-center shop-area padding-250">
      <div className="rgb-background">
        <CCard style={{backgroundColor:"#484979"}} className='w-100'>
        <CCardHeader className='tHistory-header'>
          <h1>
          Transaction History
          </h1>
        </CCardHeader>
        <CCardBody>
          {transactions.length > 0 ? (
            <CAccordion>
              {transactions.map((transaction) => (
                <CAccordionItem key={transaction.id} itemKey={transaction.id}>
                  <CAccordionHeader style={{display:'flex', justifyContent:'space-between'}}>
                    <div style={{display:'flex', flexDirection:'column'}}>
                      <span>{` Transaction ID: ${transaction.transaction_id}`}</span>
                      <span>{` Total Amount: ${formatCurrency(transaction.amount)}`}</span>
                    </div>
                    <div>
                      <span>{transaction.payment_status}</span>
                    </div>
                  </CAccordionHeader>
                  <CAccordionBody>
                    <div><strong>Transaction ID: </strong> {transaction.transaction_id}</div>
                    <div><strong>Amount: </strong>{formatCurrency(transaction.amount)}</div>
                    <div><strong>Payment Method: </strong> {transaction.payment_method}</div>
                    <div><strong>Status: </strong> {transaction.payment_status}</div>
                    <div><strong>Date: </strong> {new Date(transaction.request_date).toLocaleString()}</div>
                    <div><strong>Game: </strong> {transaction.game_id}</div>
                    <div><strong>UserID: </strong> {transaction.user_id}</div>
                    {transaction.server_id && (
                      <div><strong>Server ID: </strong> {transaction.server_id}</div>
                    )}
                  </CAccordionBody>
                </CAccordionItem>
              ))}
            </CAccordion>
          ) : (
            <CAlert color="info">No transactions found</CAlert>
          )}
        </CCardBody>
      </CCard>
      </div>
    </div>
  );
};

export default ThistoryDetails;
