export const isAuthenticated = () => {
    return !!localStorage.getItem('token');
  };


export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('user_id_nero');
  localStorage.removeItem('authToken');
};
  