import React from 'react'

function Refund() {
  return (
    <div className='bg-all-container'>
        <div className='refund-wrapper'></div>
    </div>
  )
}

export default Refund