import React from 'react'
import Footer from '../../components/footer/Footer'
import HeaderTwo from '../../components/header/HeaderTwo'
import GameDetail from './GameDetail'

function Game() {
  return (
    <>
    <HeaderTwo/>
    <main>
        <GameDetail/>
    </main>
    <Footer/>
    </>
  )
}

export default Game