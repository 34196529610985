import React from "react";
import HeaderTwo from "../../components/header/HeaderTwo";
import TopUpWallet from "./TopUpWallet";
import Footer from "../../components/footer/Footer";

function TopUpWalletDetails() {
  return (
    <>
      <HeaderTwo />
      <main>
        <TopUpWallet />
      </main>
      <Footer />
    </>
  );
}

export default TopUpWalletDetails;
