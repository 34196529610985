import React from "react";

function Maintenance() {
  return (
    <div className="maintenance-container">
        <img style={{marginBottom:20}} src="https://storage.googleapis.com/nero-assets/nero-game-logo/nero-logo-grey.png" />
      <h1>Site Under Maintenance</h1>
      <p>
        We are currently performing scheduled maintenance. Please check back
        later.
      </p>
    </div>
  );
}

export default Maintenance;
