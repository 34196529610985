import React from "react";
import HeaderTwo from "../../components/header/HeaderTwo";
import Product from "./Product";
import Footer from "../../components/footer/Footer";

function ProductDetails() {
  return (
    <>
      <HeaderTwo />
      <main>
        <Product />
      </main>
      <Footer />
    </>
  );
}

export default ProductDetails;
