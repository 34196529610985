import React, { useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Slider from 'react-slick';

import SUGAR from "../../../assets/img/store/SUGAR.png";
function NewGamesArea() {
 
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  let slider1,slider2;


  useEffect(function(){
    setNav1(slider1)
    setNav2(slider2)
  },[slider1,slider2])
   
  
  return (
  
	<section className="released-games-area gray-bg pt-115 pb-70">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8">
                  <div className="section-title text-center mb-20">
                    <h2><span className='text-black'>Selamat datang di</span> <span>Nero Games!</span></h2>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-xl-8 col-lg-12">
                  <Slider className="released-game-active"
                  asNavFor={nav2}
                  ref={slider => (slider1 = slider)}
                  arrows={false}
                  >
                    <div className="released-game-carousel">
                      <div className="released-game-item">
                        <div className="released-game-item-bg" />
                        <div className="released-game-img">
                          <img className='released-img' style={{width:"70%"}} src="https://storage.googleapis.com/nero-assets/home/cnd-med-banner.webp" alt="" />
                        </div>
                        <div className="released-game-content">
                          <div className="released-game-rating">
                            <h5>Rating :</h5>
                            <div className="released-rating">
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                            </div>
                          </div>
                          <h4>SugarPuff <span>Rush</span></h4>
                          <div className="released-game-list mb-15">
                            <ul>
                              <li><span>Category :</span>Puzzle Game</li>
                              <li><span>Model :</span>Puzzle Match Game</li>
                              <li><span>Controller :</span>Web Base Game</li>
                            </ul>
                          </div>
                          <Link to="/shop" className="btn btn-style-two">Top Up Now</Link>
                        </div>
                      </div>
                    </div>
                    <div className="released-game-carousel">
                      <div className="released-game-item">
                        <div className="released-game-item-bg" />
                        <div className="released-game-img">
                          <img style={{width:'70%'}} src="https://storage.googleapis.com/nero-assets/home/mkt-med-banner.webp" alt="" />
                        </div>
                        <div className="released-game-content">
                          <div className="released-game-rating">
                            <h5>Rating :</h5>
                            <div className="released-rating">
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                            </div>
                          </div>
                          <h4>Idle <span>Market</span></h4>
                          <div className="released-game-list mb-15">
                            <ul>
                              <li><span>Category :</span>Idle Game</li>
                              <li><span>Model :</span>Idle Games</li>
                              <li><span>Controller :</span>Web Base Game</li>
                            </ul>
                          </div>
                          <Link to="/shop" className="btn btn-style-two">Top Up Now</Link>
                        </div>
                      </div>
                    </div>
                    <div className="released-game-carousel">
                      <div className="released-game-item">
                        <div className="released-game-item-bg" />
                        <div className="released-game-img">
                          <img style={{width:'70%'}} src="https://storage.googleapis.com/nero-assets/home/jet-med-banner.webp" alt="" />
                        </div>
                        <div className="released-game-content">
                          <div className="released-game-rating">
                            <h5>Rating :</h5>
                            <div className="released-rating">
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                            </div>
                          </div>
                          <h4>Jetwing <span>Ride</span></h4>
                          <div className="released-game-list mb-15">
                            <ul>
                              <li><span>Category :</span>Arcade Game</li>
                              <li><span>Model :</span>Arcade Game</li>
                              <li><span>Controller :</span>Web Base Game</li>
                            </ul>
                          </div>
                          <Link to="/shop" className="btn btn-style-two">Top Up Now</Link>
                        </div>
                      </div>
                    </div>
                    <div className="released-game-carousel">
                      <div className="released-game-item">
                        <div className="released-game-item-bg" />
                        <div className="released-game-img">
                          <img style={{width:'70%'}} src="https://storage.googleapis.com/nero-assets/home/btc-med-banner.webp" alt="" />
                        </div>
                        <div className="released-game-content">
                          <div className="released-game-rating">
                            <h5>Rating :</h5>
                            <div className="released-rating">
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                            </div>
                          </div>
                          <h4>Bitcoin <span>Millionaire</span></h4>
                          <div className="released-game-list mb-15">
                            <ul>
                              <li><span>Category :</span>Idle Game</li>
                              <li><span>Model :</span>Idle Games</li>
                              <li><span>Controller :</span>Web Base Game</li>
                            </ul>
                          </div>
                          <Link to="/shop" className="btn btn-style-two">Top Up Now</Link>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
                <div className="col-xl-4 col-lg-12">
                  <Slider className="released-game-nav" 
                  asNavFor={nav1}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  ref={slider => (slider2 = slider)} slidesToShow={3} vertical={true} arrows={false} >
                    <div className="released-game-nav-item">
                      <img src="https://storage.googleapis.com/nero-assets/home/sugar-puff-rush-small-banner.webp" alt="sugar puff rush" />
                    </div>
                    <div className="released-game-nav-item">
                      <img src="https://storage.googleapis.com/nero-assets/home/idle-market-small-banner.webp" alt="idle market" />
                    </div>
                    <div className="released-game-nav-item">
                      <img src="https://storage.googleapis.com/nero-assets/home/jetwing-ride-small-banner.webp" alt="jetwing ride" />
                    </div>
                    <div className="released-game-nav-item">
                      <img src="https://storage.googleapis.com/nero-assets/home/bitcoin-mio-small-banner.webp" alt="bitcoin mio" />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </section>
  )
}

export default NewGamesArea