import React from 'react'

import HeroImgCta from "../../../assets/img/images/cta_img.png";
import IconCta from "../../../assets/img/icon/cta_icon.png";
import BarImg from "../../../assets/img/images/title_bar03.png"

function CtaArea() {
  return (
    <section className="cta-area cta-bg">
      <div className="flex base:flex-row flex-col items-center justify-center base:gap-5 lg:gap-10">
        
        <div className='aspect-auto w-72 base:w-96'>
          <img src={HeroImgCta} alt="hero-image-cto" className='w-full h-full' />
        </div>
        
        <div className="flex flex-col items-center justify-center gap-4">
          <img src={IconCta} alt="icon-cta" />
          <div className="text-white text-center base:text-left px-4">
            <h2 className='py-1 font-extrabold text-4xl lg:text-5xl base:max-w-[35rem]'>TOP UP GAME FAVORIT ANDA <span className='text-[#E4A101]'>DENGAH MUDAH</span></h2>
            <p className='text-white base:max-w-[30rem]'>Dengan Nero Games, Anda bisa top up game seperti PUBG, Mobile Legends, dan Clash of Clans dari mana saja. Login, pilih game, tentukan jumlah, dan bayar. Prosesnya cepat dan mudah, sehingga Anda bisa segera kembali bermain.</p>
            <img src={BarImg} alt="bar" className="my-4 mx-auto" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CtaArea