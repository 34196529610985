import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import API from "../../api";
import Slider from "react-slick";
import {
  CCard,
  CCardBody,
  CButton,
  CForm,
  CFormInput,
  CSelect,
  CContainer,
  CSpinner,
  CAlert,
  CRow,
  CCol,
} from "@coreui/react";
import SUGAR from "../../assets/img/Home/SUGAR.png";
import IDLE from "../../assets/img/Home/IDLE.png";
import { ToastContainer, toast } from "react-toastify";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const games = [
  { id: "sugarpuff", name: "SUGAR", image: SUGAR },
  { id: "idlemarket", name: "IDLE", image: IDLE },
  {
    id: "jetpack",
    name: "JET PACK",
    image: "https://storage.googleapis.com/nero-assets/nero-game-logo/JET.webp",
  },
  {
    id: "bitcoinclicker",
    name: "BITCOIN CLICKER",
    image:
      "https://storage.googleapis.com/nero-assets/nero-game-logo/BILLIONAIRE.webp",
  },
  {
    id: "warriorvsevil",
    name: "Warrior vs Evil",
    image:
      "https://storage.googleapis.com/nero-assets/home/man-vs-zombie-app.png",
  },
  {
    id: "ninjafruit",
    name: "Hayabusa Slash",
    image:
      "https://storage.googleapis.com/nero-assets/home/hayabusa-slash-app.png",
  },
];

function TopupGame() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTime, setCurrentTime] = useState("");
  const history = useHistory();

  const fetchUserData = async () => {
    try {
      const response = await API.post("get-user");
      if (response) {
        setUserData(response?.user);
      } else {
        setError(response?.data?.message || "Failed to fetch user data");
      }
    } catch (error) {
      setError("An error occurred while fetching user data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const formattedTime = now.toLocaleTimeString();
      setCurrentTime(formattedTime);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // Formik setup for top-up form
  const formik = useFormik({
    initialValues: {
      amount: "",
      game: "", // Formik's selected game field
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required("Required")
        .positive("Must be a positive number")
        .max(
          userData?.amount,
          "Insufficient amount. The input amount exceeds your available balance"
        ),
      game: Yup.string().required("Please select a game"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (parseFloat(values.amount) > userData.amount) {
        toast.error(
          "Insufficient amount. The input amount exceeds your available balance."
        );
        return;
      }

      try {
        setLoading(true); // Show loading indicator
        const response = await API.post("topup-coin", {
          amount: values.amount,
          type_game: values.game,
        });

        if (response) {
          toast.success("Top-up successful!");
          resetForm(); // Reset form on success
          await fetchUserData(); // Refetch user data to update the balance
        } else {
          toast.error(response?.data?.message || "Failed to top up");
        }
      } catch (error) {
        toast.error("An error occurred during the top-up process.");
        console.error("Top-up error:", error);
      } finally {
        setLoading(false); // Hide loading indicator
      }
    },
  });

  // Handle game selection
  const handleGameClick = (gameId) => {
    formik.setFieldValue("game", gameId); // Set the game field in Formik
  };

  if (loading) {
    return (
      <CContainer className="min-vh-100 d-flex justify-content-center align-items-center">
        <CSpinner color="primary" />
      </CContainer>
    );
  }

  if (error) {
    return (
      <CContainer className="min-vh-100 d-flex justify-content-center align-items-center bg-all-container">
        <CAlert color="danger">{error}</CAlert>
      </CContainer>
    );
  }

  // Slick slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipe: true,
    touchMove: true,
    draggable: true,
    swipeToSlide: true,
    touchThreshold: 10,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="min-vh-100 d-flex justify-content-center align-items-center bg-all-container">
      <div className="rgb-background">
        <CCard className="profile-card">
          <CCardBody>
            <div style={{ gap: 10 }} className="d-flex w-100">
              <div className="profile-header">
                <h2>Welcome {userData.username}</h2>
              </div>
              <div className="profile-header">
                <h2>
                  Nero Coin:{" "}
                  <span style={{ color: "orange", fontWeight: "bold" }}>
                    {userData.amount}
                  </span>
                </h2>
                <CButton className="game-button" href="/profile">
                  Back to Profile
                </CButton>
              </div>
            </div>
            <div className="top-up-game-header">
              <h1>Nero Games</h1>
            </div>
            <div className="game-top-up">
              <span
                style={{ color: "white", margin: "10px", fontWeight: "bold" }}
              >
                Time stamp: {currentTime}
              </span>
              <span className="line" />
              {/* Slider for game selection */}
              <div className="slider-container">
                <Slider {...settings}>
                  {games.map((game) => (
                    <div
                      key={game.id}
                      className="slider-item"
                      onClick={() => handleGameClick(game.id)}
                      style={{
                        cursor: "pointer",
                        border:
                          formik.values.game === game.id
                            ? "2px solid orange"
                            : "none",
                      }}
                    >
                      <img
                        style={{ borderRadius: 12, width: "100%" }}
                        src={game.image}
                        alt={game.name}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
              <span className="line" />
              {formik.values.game && (
                <div className="selected-game">
                  <h2
                    style={{
                      color: "white",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Selected Game:{" "}
                    {games.find((game) => game.id === formik.values.game)?.name}
                  </h2>
                </div>
              )}
            </div>

            {/* Formik Form */}
            <CForm onSubmit={formik.handleSubmit} className="mt-4">
              <CRow>
                <CCol xs="9">
                  <CFormInput
                    type="text"
                    id="amount"
                    name="amount"
                    placeholder="Input Nominal Topup"
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{ borderRadius: "12px" }}
                  />
                  {formik.touched.amount && formik.errors.amount ? (
                    <div style={{ color: "red", marginTop: "5px" }}>
                      {formik.errors.amount}
                    </div>
                  ) : null}
                </CCol>
                <CCol
                  xs="3"
                  className="d-flex align-items-center justify-content-center"
                >
                  <CButton
                    type="submit"
                    color="warning"
                    style={{
                      borderRadius: "12px",
                      padding: "10px 20px",
                      fontWeight: "bold",
                    }}
                  >
                    Bayar
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </div>
      <ToastContainer />
    </div>
  );
}

export default TopupGame;
