import React from 'react'
import HeaderTwo from '../../components/header/HeaderTwo'
import Register from './Register'
import Footer from '../../components/footer/Footer'

function RegisterDetail() {
  return (
    <>
    <HeaderTwo/>
    <main>
        <Register/>
    </main>
    <Footer/>
    </>
  )
}

export default RegisterDetail