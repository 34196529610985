import React from "react";
import banner1 from "../../assets/img/about/banner1.png";
function Aboutus() {
  return (
    <div className="bg-all-container">
      <div className="flex flex-col items-center justify-center mb-20 mt-32 gap-2">
        <div className="Content-Wrapper flex">
          <img className="banner-img" src={banner1} alt="" />
          <div className="Text-wrapper">
            <h2>Tentang Nero Games</h2>
            <p style={{color:'white'}}>
            Selamat datang di Nero Games ID, tujuan utama Anda untuk game online berbasis web dan layanan top-up game mobile. Kami bersemangat tentang dunia game dan berdedikasi untuk memberikan pengalaman terbaik bagi para gamer.
            </p>
          </div>
        </div>

        <div className="Content-Wrapper banner2">
          <h2>Game Online Berbasis Web</h2>
          <p style={{color:'white'}}>
          Di Nero Games ID, kami menawarkan beragam pilihan game online berbasis web yang cocok untuk semua jenis pemain. Apakah Anda menyukai strategi, aksi, atau game kasual, platform kami menyediakan sesuatu untuk semua orang. Nikmati permainan tanpa hambatan langsung dari browser Anda tanpa perlu mengunduh atau menginstal apapun.
          </p>
        </div>

        <div className="Row">
          <div className="Content-Wrapper w-100  banner-3">
          <h2><span style={{color:'#FFC700'}}>Layanan Top-Up Game Mobile</span></h2>
          <p style={{color:'white'}}>Kami memahami betapa pentingnya untuk tetap unggul dalam permainan favorit Anda. Oleh karena itu, kami menyediakan layanan top-up untuk berbagai game mobile populer seperti Mobile Legends: Bang Bang (MLBB), PUBG, dan banyak lagi. Tingkatkan pengalaman bermain Anda dengan top-up yang mudah dan aman, memastikan Anda memiliki sumber daya yang diperlukan untuk mendominasi medan perang.</p>
          </div>
          <div className="Content-Wrapper w-100 m-0-10 banner-4">
            <h2>Reseller Top-Up<span style={{color:'#FFC700'}}> Game Mobile</span></h2>
            <p style={{color:'white'}}>Selain melayani gamer individu, kami juga bertindak sebagai reseller untuk top-up game mobile. Jika Anda ingin menawarkan layanan top-up kepada pelanggan Anda sendiri, NeroGames.id adalah mitra terpercaya Anda. Kami menyediakan harga kompetitif dan layanan yang dapat diandalkan untuk membantu Anda mengembangkan bisnis. Di NeroGames.id, kami berkomitmen untuk meningkatkan pengalaman bermain Anda dengan layanan berkualitas tinggi dan dukungan yang tak tertandingi. Bergabunglah dengan kami dan tingkatkan permainan Anda hari ini!</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
