import React from 'react'
import HeaderTwo from '../../components/header/HeaderTwo'
import TnC from './TnC'
import Footer from '../../components/footer/Footer'
import TnCBreadCrumb from './TnCBreadCrumb'

function TnCDetail() {
  return (
    <>
    <HeaderTwo/>
    <main>
        <TnCBreadCrumb/>
        <TnC/>
    </main>
    <Footer/>
    </>
  )
}

export default TnCDetail