import React from 'react'
import Confirmation from './Confirmation'
function ConfirmationDetails() {
  return (
    <>
    <main>
        <Confirmation/>
    </main>
    </>
  )
}

export default ConfirmationDetails